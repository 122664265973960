import React, { useEffect, useState } from "react";
import { Container, Grid, Icon, MenuItem, Typography, Loader, Badge, Button, Modal, ModalBody, Tabs, Line, Box, ModalHeader, useParams } from "@cbmisorg/client-app";
import { routeMngRequestedJoinCtrl } from "./controler/routeMngRequestedJoin.controller";
import ShredHeader from "../../../sharedUi/header/ShredHeader";
import { dictionary } from "../../../../appHelper/appLanguage/dictionary";
import NoResultImg from "../../../sharedUi/noResultImg/NoResultImg";
import useCheckCat from "../../../../appHelper/appHook/useCheckCat";
import * as appVariables from "../../../../appHelper/appVariables";
import { FormData, FormField } from "../../../../appHelper/formApi/FormAPI";
import * as appColor from "../../../../appHelper/appColor";
import { useApp } from "../../../../../client/configuration/contextapi/context";
import { getCategoryCache } from "../../../../appHelper/appFunctions";

const objCat = getCategoryCache();

function RouteMngRequestedJoin({ blnIsStartup = false, blnResearcher = false }) {
  useCheckCat();
  const strUserStatusKey = blnIsStartup ? "bigStartupUserStatusID" : "bigUserStatusID";
  const { appState, appDispatch } = useApp();
  const { strTrainingType } = useParams();

  const lang = appState.clientInfo.strLanguage;
  const labels = dictionary?.components?.mnageRequestJoin;
  const [isLoading, setIsLoading] = useState(false);
  const [stateInfo, setStateInfo] = useState({
    blnIsInitDone: false,
    bigCurrentTab: appVariables.objUserStatus.Applicant,
    lstUser: [],
    objStatistic: {
      [appVariables.objUserStatus.Applicant]: 0,
      [appVariables.objUserStatus.Approved]: 0,
      [appVariables.objUserStatus.Rejected]: 0,
    },
    blnOpenSortModal: false,
    blnOpenActionModal: false,

    blnOpenUserDetailModal: false,
    objUserDetail: {},

    intNumViewItem: 0,
    blnOpenIntroductoryVideo: false,
  });

  const fldSort = FormData({
    intGPA: { strControl: "list", lstOption: [] },
    bigDegreeID: { strControl: "list", lstOption: [] },
    bigMajorID: { strControl: "list", lstOption: [] },
    bigEmploymentID: { strControl: "list", lstOption: [] },
    bigCountryOfCitizenID: { strControl: "list", lstOption: [] },
    bigCountryOfResidenceID: { strControl: "list", lstOption: [] },
    strUniversityName: { strControl: "list", lstOption: [] },
    bigAcademicYearID: { strControl: "list", lstOption: [] },
    lstUserRole: { strControl: "list", lstOption: [] },
    strFullNameSearch: { strControl: "text", objLanguage: false },
  });

  const fields = FormData({
    strUserEmail: { strControl: "text", objLanguage: false },
    strUserPhone: { strControl: "text", objLanguage: false },
    strFullName: { strControl: "text", objLanguage: false },
    dtmDob: { strControl: "date", objLanguage: false },
    strGovID: { strControl: "text", objLanguage: false },
    bigMajorID: { strControl: "list", lstOption: [] },
    strAddress: { strControl: "text", objLanguage: false },
    bigDegreeID: { strControl: "list", lstOption: [] },
    intGPA: { strControl: "list", lstOption: [] },
    bigGender: { strControl: "list", lstOption: [] },
    dtmGradDate: { strControl: "date", lstOption: [] },
    bigEmploymentID: { strControl: "list", lstOption: [] },
    bigCountryOfCitizenID: { strControl: "list", lstOption: [] },
    bigCountryOfResidenceID: { strControl: "list", lstOption: [] },
    strUniversityName: { strControl: "list", lstOption: [] },
    bigAcademicYearID: { strControl: "list", lstOption: [] },
    strWorkPlace: { strControl: "text", objLanguage: false },
    strPositionTitle: { strControl: "text", objLanguage: false },
  });

  
  const handlers = routeMngRequestedJoinCtrl({ appState, appDispatch, strTrainingType });
  const initData = handlers.initData({ isLoading, setIsLoading, state: stateInfo, setState: setStateInfo, fldSort, fields, strTrainingType });
  const onChangeCountry = handlers.onChangeCountry({ fldSort });
  const resetFilterFields = handlers.resetFilterFields({ fldSort, state: stateInfo, setState: setStateInfo });
  const filterUser = handlers.filterUser({ fldSort, state: stateInfo });
  const openUserDetail = handlers.openUserDetail({ fields, state: stateInfo, setState: setStateInfo, strTrainingType });
  const onChangeCountryForUser = handlers.onChangeCountryForUser({ fields });
  const confirmUpdateUserInDB = handlers.confirmUpdateUserInDB({ isLoading, setIsLoading, state: stateInfo, setState: setStateInfo, fields, strTrainingType });
  const openIntroductoryVideo = handlers.openIntroductoryVideo({ state: stateInfo, setState: setStateInfo });

  useEffect(() => {
    if (!stateInfo?.blnIsInitDone) {
      initData();
    }
  }, []);
  return (
    <React.Fragment>
      <ShredHeader title={labels?.pageTitle?.[strTrainingType]?.[lang]} lang={lang} blnBottomBar={false} />
      <Container py-3>
        {isLoading ? <Loader backdrop={true} color={appColor.App_Primary_Color} /> : null}
        <Grid container justify={"center"}>
          {!stateInfo?.lstUser?.length ? (
            <NoResultImg />
          ) : (
            <>
              <Grid item xs={12} p-0 container justify={"space-between"}>
                {strTrainingType === appVariables.objTrainingToSupport?.fullstack?.key ? (
                  <Grid item xs={11} sm="11" p-0 pt-2 sx={{ ".cbmis-tabs": { width: "100% !important", maxWidth: "99% !important" } }}>
                    <Tabs
                      mode="box"
                      activeColor="primary"
                      p-0
                      dir={lang === "arb" ? "rtl" : "ltr"}
                      currentTab={1}
                      tabsContent={[
                        {
                          tabLabel: (
                            <Grid container>
                              <Grid item xs="9" sm="auto">
                                {labels?.pendingUser?.[lang]}
                              </Grid>
                              <Grid item xs="2" sm="auto">
                                <Box py-0 px-2 m-0 color=" " sx={{ background: appColor.App_Primary_Color }}>
                                  <Typography as="caption" color="light" p-0 m-0>
                                    <b>{stateInfo?.objStatistic?.[appVariables.objUserStatus.Applicant] || 0}</b>
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          ),
                          onChange: () => setStateInfo({ ...stateInfo, bigCurrentTab: appVariables.objUserStatus.Applicant }),
                        },
                        {
                          tabLabel: (
                            <Grid container>
                              <Grid item xs="9" sm="auto">
                                {labels?.approvedUser?.[lang]}
                              </Grid>
                              <Grid item xs="2" sm="auto">
                                <Box py-0 px-2 m-0 color=" " sx={{ background: appColor.App_Dark_green }}>
                                  <Typography as="caption" color="light" p-0 m-0>
                                    <b>{stateInfo?.objStatistic?.[appVariables.objUserStatus.Approved] || 0}</b>
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          ),
                          onChange: () => setStateInfo({ ...stateInfo, bigCurrentTab: appVariables.objUserStatus.Approved }),
                        },
                        {
                          tabLabel: (
                            <Grid container>
                              <Grid item xs="9" sm="auto">
                                {labels?.excludedUser?.[lang]}
                              </Grid>
                              <Grid item xs="2" sm="auto">
                                <Box py-0 px-2 m-0 color=" " sx={{ background: appColor?.App_Dark_orange }}>
                                  <Typography as="caption" color="light" p-0 m-0>
                                    <b>{stateInfo?.objStatistic?.[appVariables.objUserStatus.Rejected] || 0}</b>
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          ),
                          onChange: () => setStateInfo({ ...stateInfo, bigCurrentTab: appVariables.objUserStatus.Rejected }),
                        },
                      ]}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={11} sm="11" p-0 pt-2 sx={{ ".cbmis-tabs": { width: "100% !important", maxWidth: "99% !important" } }}>
                    <Tabs
                      mode="box"
                      activeColor="primary"
                      p-0
                      dir={lang === "arb" ? "rtl" : "ltr"}
                      currentTab={1}
                      tabsContent={[
                        {
                          tabLabel: (
                            <Grid container>
                              <Grid item xs="9" sm="auto">
                                {labels?.allNumber?.[lang]}
                              </Grid>
                              <Grid item xs="2" sm="auto">
                                <Box py-0 px-2 m-0 color=" " sx={{ background: appColor.App_Primary_Color }}>
                                  <Typography as="caption" color="light" p-0 m-0>
                                    <b>{stateInfo?.lstUser?.length || 0}</b>
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          ),
                          onChange: () => setStateInfo({ ...stateInfo, bigCurrentTab: appVariables.objUserStatus.Applicant }),
                        },
                      ]}
                    />
                  </Grid>
                )}
                <Grid item xs="1">
                  <Button mode="link" icon="filterAlt" size="sm" p-1 onClick={() => setStateInfo({ ...stateInfo, blnOpenSortModal: true })} />
                </Grid>
              </Grid>

              <Grid item xs={12} container>
                <Grid item xs={12} sx={{ background: "#ffffff" }} p-0>
                  <FormField objHandler={fldSort?.strFullNameSearch} endIcon={"search"} dir={lang === "arb" ? "rtl" : "ltr"} />
                </Grid>
              </Grid>

              {stateInfo?.lstUser?.map((item, index) => {
                if (index === 0) {
                  stateInfo.intNumViewItem = 0;
                }
                if (!filterUser(item)) {
                  if (!stateInfo.intNumViewItem && index >= stateInfo?.lstUser?.length - 1) {
                    return <NoResultImg key={index} xs={6} />;
                  }
                  return null;
                }
                ++stateInfo.intNumViewItem;

                return (
                  <Grid item xs="12" key={item?.bigUserID}>
                    <MenuItem
                      sx={{
                        width: "100%",
                        background: !item?.jsnUserInfo?.blnIsSeen?.[strTrainingType]
                          ? appColor.App_Light_blue
                          : String(item?.[strUserStatusKey]) === String(appVariables.objUserStatus.Applicant)
                          ? appColor.App_Light_Color
                          : String(item?.[strUserStatusKey]) === String(appVariables.objUserStatus.Approved)
                          ? appColor.App_Light_green
                          : appColor.App_Light_Gray,
                        color:
                          String(item?.[strUserStatusKey]) === String(appVariables.objUserStatus.Applicant)
                            ? appColor.App_Primary_Color
                            : String(item?.[strUserStatusKey]) === String(appVariables.objUserStatus.Approved)
                            ? appColor.App_Dark_green
                            : appColor.App_Gray_Color,
                      }}
                      headItem={
                        item?.jsnUserInfo?.blnIsSeen?.[strTrainingType] === true ? (
                          <Icon icon={"AccountBox"} pt-1 color="current-color" />
                        ) : (
                          <Badge mode="dot" sx={{ background: "var(--cbmis-success) !important" }}>
                            <Icon icon={"AccountBox"} pt-1 color={"primary"} />
                          </Badge>
                        )
                      }
                      outlined
                    >
                      <Grid container justify={"space-between"}>
                        <Grid item xs="8" pb-0>
                          <Typography as="caption" pb-0 color="current-color" className="traineeName">
                            {typeof item?.jsnUserInfo?.strFullName === "object" ? item?.jsnUserInfo?.strFullName?.[lang] : item?.jsnUserInfo?.strFullName}
                          </Typography>
                        </Grid>
                        <Grid item px-0>
                          <Button
                            icon="visibility"
                            p-0
                            onClick={openUserDetail(item)}
                            color={
                              String(item?.[strUserStatusKey]) === String(appVariables.objUserStatus.Applicant)
                                ? appColor.App_Primary_Color
                                : String(item?.[strUserStatusKey]) === String(appVariables.objUserStatus.Approved)
                                ? appColor.App_Dark_green
                                : appColor.App_Gray_Color
                            }
                          />
                        </Grid>
                      </Grid>
                    </MenuItem>
                  </Grid>
                );
              })}
            </>
          )}
        </Grid>
      </Container>

      <Modal open={stateInfo.blnOpenSortModal} eventClose={() => setStateInfo({ ...stateInfo, blnOpenSortModal: false })}>
        <ModalHeader>
          <Typography as="subtitle2" className="textcenter">
            {labels?.filterBtn?.[lang]}
          </Typography>
        </ModalHeader>
        <ModalBody>
          <Grid container>
            <Grid item xs={12} md={6}>
              <FormField objHandler={fldSort.bigCountryOfCitizenID} icon="flag" dir={lang === "arb" ? "rtl" : "ltr"} />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormField objHandler={fldSort.bigCountryOfResidenceID} icon="LocationOn" onChange={onChangeCountry} dir={lang === "arb" ? "rtl" : "ltr"} />
            </Grid>
            <Grid item xs={12}>
              <FormField objHandler={fldSort.strUniversityName} icon="locationCity" dir={lang === "arb" ? "rtl" : "ltr"} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <FormField objHandler={fldSort.bigMajorID} icon="localLibrary" dir={lang === "arb" ? "rtl" : "ltr"} />
            </Grid>
            <Grid item xs={12}>
              <FormField objHandler={fldSort.bigEmploymentID} icon="work" dir={lang === "arb" ? "rtl" : "ltr"} />
            </Grid>
            <Grid item xs={12}>
              <FormField objHandler={fldSort.bigDegreeID} icon="historyEdu" dir={lang === "arb" ? "rtl" : "ltr"} />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <FormField objHandler={fldSort.bigAcademicYearID} icon="school" dir={lang === "arb" ? "rtl" : "ltr"} />
            </Grid>
            <Grid item xs={12}>
              <FormField objHandler={fldSort.intGPA} icon="insertChartOutlined" dir={lang === "arb" ? "rtl" : "ltr"} />
            </Grid>
          </Grid>

          <Grid container justify="space-between" mt-4>
            <Grid item>
              <Button icon="filterList" size="sm" label={labels?.filterBtn?.[lang]} p-1 onClick={() => setStateInfo({ ...stateInfo, blnOpenSortModal: false })} />
            </Grid>
            <Grid item>
              <Button mode="outlined" label={dictionary?.shared?.btn?.rest?.[lang]} icon="restartAlt" size="sm" onClick={resetFilterFields} />
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>

      <Modal open={stateInfo.blnOpenUserDetailModal} eventClose={() => setStateInfo({ ...stateInfo, blnOpenUserDetailModal: false })} size="xl">
        <ModalHeader>
          <Typography as="subtitle2" className="textcenter">
            {labels?.traineeInfo?.[lang]}
          </Typography>
        </ModalHeader>
        <ModalBody>
          <Grid container justify={"space-between"}>
            <Grid item>
              <Typography as="body2">
                {labels?.dtmRegisterDate?.[lang]}:{" "}
                {objCat?.Root?.toSupport?.[strTrainingType]?.blnIsTraining?.value?.eng === "true"
                  ? stateInfo?.objUserDetail?.jsnUserInfo?.dtmRegisterStudent || "-"
                  : stateInfo?.objUserDetail?.jsnUserInfo?.dtmRegisterResearcher || "-"}
              </Typography>
            </Grid>
            {!stateInfo?.objUserDetail?.jsnUserInfo?.lstIntroductoryVideo?.length ? null : (
              <Grid item>
                <Button label={<Typography as="caption">{labels?.introductoryVideo?.[lang]}</Typography>} size="sm" onClick={openIntroductoryVideo} />
              </Grid>
            )}
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <FormField objHandler={fields.strFullName} icon="person" />
            </Grid>
            <Grid item xs={12}>
              <FormField objHandler={fields.strUserEmail} icon="email" readOnly={true} dir={"ltr"} />
            </Grid>
            <Grid item xs={12}>
              <FormField objHandler={fields.strUserPhone} icon="phone" readOnly={true} dir={"ltr"} />
            </Grid>
            {!fields.bigCountryOfCitizenID?.getValue()?.key ? null : (
              <Grid item xs={12} md={6}>
                <FormField objHandler={fields.bigCountryOfCitizenID} icon="flag" dir={lang === "arb" ? "rtl" : "ltr"} />
              </Grid>
            )}
            {!fields.bigCountryOfResidenceID?.getValue()?.key ? null : (
              <Grid item xs={12} md={6}>
                <FormField objHandler={fields.bigCountryOfResidenceID} icon="LocationOn" onChange={onChangeCountryForUser} dir={lang === "arb" ? "rtl" : "ltr"} />
              </Grid>
            )}
            {!fields.strUniversityName?.getValue()?.key ? null : (
              <Grid item xs={12} md={6}>
                <FormField objHandler={fields.strUniversityName} icon="business" dir={lang === "arb" ? "rtl" : "ltr"} />
              </Grid>
            )}
            {!fields.dtmDob?.getValue() ? null : (
              <Grid item xs={12} md={6}>
                <FormField objHandler={fields.dtmDob} icon="cake" dir={lang === "arb" ? "rtl" : "ltr"} />
              </Grid>
            )}
            {!fields.strGovID?.getValue() ? null : (
              <Grid item xs={12} md={6}>
                <FormField objHandler={fields.strGovID} icon="creditCard" dir={lang === "arb" ? "rtl" : "ltr"} />
              </Grid>
            )}
            {!fields.bigMajorID?.getValue()?.key ? null : (
              <Grid item xs={12} md={6}>
                <FormField objHandler={fields.bigMajorID} icon="localLibrary" dir={lang === "arb" ? "rtl" : "ltr"} />
              </Grid>
            )}
            {!fields.strAddress?.getValue() ? null : (
              <Grid item xs={12} md={6}>
                <FormField objHandler={fields.strAddress} icon="directionsCar" dir={lang === "arb" ? "rtl" : "ltr"} />
              </Grid>
            )}
            {!fields.bigDegreeID?.getValue()?.key ? null : (
              <Grid item xs={12} md={6}>
                <FormField objHandler={fields.bigDegreeID} icon="historyEdu" dir={lang === "arb" ? "rtl" : "ltr"} />
              </Grid>
            )}
            {!fields.bigGender?.getValue()?.key ? null : (
              <Grid item xs={12} md={6}>
                <FormField objHandler={fields.bigGender} icon="wc" dir={lang === "arb" ? "rtl" : "ltr"} />
              </Grid>
            )}
            {!fields.dtmGradDate?.getValue() ? null : (
              <Grid item xs={12} md={6}>
                <FormField objHandler={fields.dtmGradDate} icon="school" dir={lang === "arb" ? "rtl" : "ltr"} />
              </Grid>
            )}
            {!fields.bigEmploymentID?.getValue()?.key ? null : (
              <Grid item xs={12} md={6}>
                <FormField objHandler={fields.bigEmploymentID} icon="work" dir={lang === "arb" ? "rtl" : "ltr"} />
              </Grid>
            )}

            {!fields.bigAcademicYearID?.getValue()?.key ? null : (
              <Grid item xs={12} md={6}>
                <FormField objHandler={fields.bigAcademicYearID} icon="today" dir={lang === "arb" ? "rtl" : "ltr"} />
              </Grid>
            )}
            {!fields.intGPA?.getValue()?.key ? null : (
              <Grid item xs={12} md={6}>
                <FormField objHandler={fields.intGPA} icon="barChart" dir={lang === "arb" ? "rtl" : "ltr"} />
              </Grid>
            )}
            {!fields.strWorkPlace?.getValue() ? null : (
              <Grid item xs={12} md={6}>
                <FormField objHandler={fields.strWorkPlace} icon="accountBalance" dir={lang === "arb" ? "rtl" : "ltr"} />
              </Grid>
            )}
            {!fields.strPositionTitle?.getValue() ? null : (
              <Grid item xs={12} md={6}>
                <FormField objHandler={fields.strPositionTitle} icon="work" dir={lang === "arb" ? "rtl" : "ltr"} />
              </Grid>
            )}
          </Grid>
          <React.Fragment>
            <Line my-3 />
            <Grid container justify={"center"}>
              <Grid item>
                <Button
                  label={labels?.update?.[lang]}
                  onClick={confirmUpdateUserInDB(stateInfo?.objUserDetail?.[strUserStatusKey], objCat?.Root?.toSupport?.[strTrainingType]?.blnIsTraining?.value?.eng === "true")}
                />
              </Grid>
            </Grid>
          </React.Fragment>
        </ModalBody>
      </Modal>

      <Modal
        open={stateInfo?.blnOpenActionModal}
        eventClose={() => {
          setStateInfo({ ...stateInfo, blnOpenActionModal: false });
        }}
        size={"sm"}
      >
        <ModalHeader>
          <Typography as="subtitle2" className="textCenter">
            {dictionary?.shared?.confirmationMsg?.procedure?.[lang]}
          </Typography>
        </ModalHeader>
        <ModalBody>
          <Grid container justify={"center"}>
            {String(stateInfo.objUserDetail?.[strUserStatusKey]) === String(appVariables.objUserStatus.Applicant) || !stateInfo?.objUserDetail?.[strUserStatusKey] ? (
              <Grid item xs="12">
                <Typography as="body2">{labels.note?.[lang]}</Typography>
              </Grid>
            ) : String(stateInfo.objUserDetail?.[strUserStatusKey]) === String(appVariables.objUserStatus.Approved) ? (
              <Grid item xs="12">
                <Typography as="body2">{labels.noteApproved?.[lang]}</Typography>
              </Grid>
            ) : (
              <Grid item xs="12">
                <Typography as="body2">{labels.notExclude?.[lang]}</Typography>
              </Grid>
            )}
          </Grid>
          <Grid container spacing={3} justify={"center"}>
            {String(stateInfo?.objUserDetail?.[strUserStatusKey]) === String(appVariables?.objUserStatus?.Approved) ? (
              <Grid item />
            ) : (
              <Grid item>
                <Button
                  label={labels?.approve?.[lang]}
                  color="success"
                  size="sm"
                  sx={{ width: "55px !important" }}
                  onClick={confirmUpdateUserInDB(appVariables.objUserStatus.Approved)}
                />
              </Grid>
            )}
            {String(stateInfo?.objUserDetail?.[strUserStatusKey]) === String(appVariables?.objUserStatus?.Rejected) ? null : (
              <Grid item>
                <Button
                  label={labels?.exception?.[lang]}
                  color="error"
                  size="sm"
                  sx={{ width: "55px !important" }}
                  onClick={confirmUpdateUserInDB(appVariables.objUserStatus.Rejected)}
                />
              </Grid>
            )}
          </Grid>
        </ModalBody>
      </Modal>

      <Modal open={stateInfo?.blnOpenIntroductoryVideo} eventClose={openIntroductoryVideo}>
        <ModalHeader>
          <Typography as="subtitle2" className="textCenter">
            {labels?.introductoryVideo?.[lang]} - {stateInfo?.objUserDetail?.jsnUserInfo?.strFullName?.[lang] || stateInfo?.objUserDetail?.jsnUserInfo?.strFullName}
          </Typography>
        </ModalHeader>
        <ModalBody>
          <Container>
            <Grid container justify={"center"}>
              <Grid item xs="12">
                <video controls className="videoTraining">
                  <source src={appVariables.App_Server_Url_DownloadFiles + stateInfo?.objUserDetail?.jsnUserInfo?.lstIntroductoryVideo?.[0]?.path} type="video/mp4" />
                </video>
              </Grid>
            </Grid>
          </Container>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}

export default RouteMngRequestedJoin;
