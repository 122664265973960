import * as appPages from "./appPages";


const appRouting = {
  landingPage: { url: appPages?.landingPage, featureID: 8786507229 },

  Public_Login: { url: appPages?.login, featureID: 2580267594 },
  Public_Initiative: { url: appPages?.initiative, featureID: 0 },

  Reg_Activity_Guest: { url: appPages?.regActivityGuest, featureID: 8786507229 },
  Register: { url: appPages?.register, featureID: 5094561645 },
  Registration: { url: appPages?.registration, featureID: 5094561645 },
  TrainingRegistration: { url: appPages?.trainingRegistration, featureID: 5094561645 },
  RegForum: { url: appPages.RegForum, featureID: 2040903837 },
  RegForumToCert: { url: appPages.RegForumToCert, featureID: 2040903837 },
  RegNewForum: { url: appPages.RegNewForum, featureID: 8786507229 },

  ForumBadge: { url: appPages.ForumBadge, featureID: 2253742539 },

  surveymonkey: { url: appPages.surveymonkey, featureID: 5964158935 },
  surveymonkeyWithAnswerID: { url: appPages.surveymonkeyWithAnswerID, featureID: 5964158935 },
  surveyThank: { url: appPages.surveyThank, featureID: 5964158935 },

  restPassword: { url: appPages?.restPassword, featureID: 1446339286 },
  
  page404: { url: appPages?.page404, featureID: 0 },
  media: { url: appPages?.media, featureID: 8786507229 },

  Account: { url: appPages?.account, featureID: 6759118668 , featureAuth: true},
  Profile: { url: appPages.profile, featureID: 3195608470 , featureAuth: false},
  SponsorProfile: { url: appPages.supportinfo, featureID: 6759118668 , featureAuth: false},
  
  Generate_Certificate: { url: appPages.Generate_Certificate, featureID: 8898633684 , featureAuth: false},
  ECertificate: { url: appPages.ECertificate, featureID: 8898633684 , featureAuth: false},

  MngUsers: { url: appPages.MngUsers, featureID: 8618062501 , featureAuth: true},
  ManageMedia: { url: appPages.ManageMedia, featureID: 3578590965 , featureAuth: true},
  MngRegForum: { url: appPages.MngRegForum, featureID: 6300172818 , featureAuth: true},

  MngSurvey: { url: appPages.MngSurvey, featureID: 7220067466 , featureAuth: true},
  MngSurveySave: { url: appPages.MngSurveySave, featureID: 7220067466 , featureAuth: true},

};
export default appRouting;
