import { Button, Container, Glass, Grid, Paper, Typography, useNavigate } from "@cbmisorg/client-app";
import React from "react";
import { dictionary } from "../../../../../appHelper/appLanguage/dictionary";
import * as appColor from "../../../../../appHelper/appColor";
import { objUserRole } from "../../../../../appHelper/appVariables";
import { generatePath } from "react-router-dom";
import appRouting from "../../../../../../client/configuration/routing/appRouting";
import { Icon } from "@cbmisorg/icons";

function ITC({ lang, label = null, blnTitle = true, appState }) {
  const labels = dictionary?.components?.public?.landingPage?.home?.itc;
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Grid container py-10 id="itc" px-0>
        <Grid item xs="12" container justify="center">
          <Container>
            <Grid container justify={"center"} spacing={3} pb-sm-8 pb-md-8 pb-lg-8 pb-xl-8>
              {!blnTitle ? null : (
                <Grid item xs="12" pb-7>
                  <Typography as="h4" color="primary">
                    {label?.title?.[lang] || labels?.title?.[lang]}
                  </Typography>
                </Grid>
              )}
              {!label?.subTitle ? null : (
                <Grid item xs="12" pb-7>
                  <Typography as="subtitle1" color="primary">
                    {label?.subTitle?.[lang]}
                  </Typography>
                </Grid>
              )}
              {labels?.lst?.map((item, index) => (
                <Grid item xs="12" key={String(item?.id) + String(index)}>
                  <Paper
                    elevation={0}
                    outline={0}
                    style={{
                      background: `url(${item?.strImage})`,
                      backgroundAttachment: "fixed",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <Grid container>
                      <Grid item xs="12">
                        <Paper elevation={0} outline={1} p-0>
                          <Glass sx={{ height: "auto", position: "relative", padding: "0", margin: "0", width: "100%" }}>
                            <Grid container spacing={3} p-3 justify={"center"}>
                              <Grid item xs="12">
                                <Typography as="subtitle2" color={"#fff"}>
                                  {item?.title?.[lang]}
                                </Typography>
                              </Grid>
                              <Grid item xs="12" className="descriptionText5Container">
                                <Typography as="body2" color="#fff" sx={{ textAlign: "justify !important" }} className="descriptionText5">
                                  {item?.dec?.[lang]}
                                  <br /> <br />
                                  {!item?.dec2?.[lang] ? null : (
                                    item?.dec2?.[lang]
                                )}
                                </Typography>
                                <Icon icon="KeyboardArrowUp" size="30px" color={appColor.App_orang_color} onClick={() => {}} key="arrowClose_icon" className="arrowTopClose"/>

                              </Grid>
  
                            </Grid>
                            <Grid container spacing={2} justify={"center"} pb-5>
                              <Grid item xs="12" sm="auto">
                                <Button
                                  label={item?.btn?.[lang]}
                                  color="primary"
                                  sx={{ border: `2px solid ${appColor.App_orang_color} !important`, width: "100% !important", maxWidth: "150px !important" }}
                                  onClick={() =>
                                    window.open(generatePath(item?.url, { strTrainingType: item?.trainingKey, state: { roleFkID: objUserRole?.[item?.roleKey] } }), "_blank")
                                  }
                                  py-0
                                  px-3
                                />
                              </Grid>
                              {!item?.btn2?.[lang] || appState?.userInfo?.blnIsStudent ? null : (
                                <Grid item xs="12" sm="auto">
                                  <Button
                                    label={item?.btn2?.[lang]}
                                    color="primary"
                                    sx={{ border: `2px solid ${appColor.App_orang_color} !important`, width: "100% !important", maxWidth: "150px !important" }}
                                    onClick={()=>navigate(generatePath(appRouting?.TrainingRegistration?.url, {countrycode: appState?.clientInfo?.clientCountryCode? appState?.clientInfo?.clientCountryCode  : "kw"}))}

                                    py-0
                                    px-3
                                  />
                                </Grid>
                              )}
                            </Grid>
                          </Glass>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ITC;
