import { ClientTest, ClientFunction } from "@cbmisorg/helper";
import * as appVariables from "../configuration/appVariables";
// import { addErrorDB } from "../graphql/resolver/monitoringError";

export default ClientFunction;

export const logError = (strPageInfo, strTitle, strMessage) => {
  ClientTest.logError(strPageInfo, strTitle, strMessage, appVariables.App_IsInTestingMode);
  // addErrorDB(strPageInfo, { error: strMessage, strTitle });
};

export const logMessage = (strPageInfo, strTitle, strMessage) => {
  ClientTest.logError(strPageInfo, strTitle, strMessage, appVariables.App_IsInTestingMode);
};
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//#region localStorage func

export function setAppLocalStorage(objAppState) {
  if (typeof localStorage === "undefined") {
    return;
  }
  ClientFunction.setAppLocalStorage(
    objAppState,
    appVariables.App_LocalStorage_Name,
    appVariables.App_LocalStorage_Is_Encrypted,
    appVariables.App_LocalStorage_Encrypted_KEY
  );
}

export function getAppLocalStorage() {
  if (typeof localStorage === "undefined") {
    return {};
  }
  return ClientFunction.getAppLocalStorage(appVariables.App_LocalStorage_Name, appVariables.App_LocalStorage_Is_Encrypted, appVariables.App_LocalStorage_Encrypted_KEY);
}

export function clearAppLocalStorage(objAppState) {
  if (typeof localStorage === "undefined") {
    return;
  }
  ClientFunction.recursionResetObject(objAppState?.userInfo);

  //#region hooks
  objAppState.clientInfo.blnIsUserLoggedIn = false;
  objAppState.clientInfo.dtmLastLoggingIn = "";

  objAppState.clientInfo.dtmLastCheckedCatInfo = "";
  //#endregion

  objAppState.clientInfo.strLanguage = "eng";
  objAppState.userInfo.bigUserRoleID = appVariables.Role_Public;

  setAppLocalStorage(objAppState);
}

// ---------------------------------------------------------------------------------------------------------------------------------------

export const {
  addDaysToDate,
  blnIsTodayBiggerThanTheDate,
  camelize,
  checkFileName,
  checkIsObjectFine,
  checkIsValidDate,
  checkNumber,
  checkParseObject,
  clearSessionStorageObject,
  compareTwoArraysIsEqual,
  convertDateToInputForm,
  copyTextToClipboard,
  decryptText,
  downloadFile,
  encryptText,
  excelDateToJSDate,
  fetchFileFromUrlAndDownload,
  generateID,
  //generateRandomString,
  generateRandomStringSecure,
  getAppSessionStorage,
  getCurrentDate,
  getDateUSFormat,
  getDifferenceBetweenTwoDate,
  getISODate,
  getKeyByValue,
  getRandomNumber,
  getRandomNumberSecure,
  getSpecificDateFromNow,
  isDate,
  normalizeText,
  parseArabicNumber,
  processFile,
  recursionResetObject,
  resizeImg,
  safeInvoke,
  scrollToElement,
  setAppSessionStorage,
  trimDash,
  compressText,
  decompressText,
} = ClientFunction;

// ---------------------------------------------------------------------------------------------------------------------------------------
//#region category function
export function getCategoryCache() {
  try {
    let result = decompressText(getAppLocalStorage()?.clientInfo?.objCatInfo);
    return result;
  } catch {
    return {};
  }
}

export function appCategoryGetValue(objCategory, id_or_name, strMiddleKey = null, blnIsParentKey = false) {
  try {
    let result = {};
    let lstCat = Object.entries(objCategory);

    for (let i = 0; i < lstCat.length; i++) {
      let [key, value] = lstCat[i];
      if (key === "id" || key === "value") {
        continue;
      }

      if (strMiddleKey) {
        if (blnIsParentKey) {
          if (!Number.isNaN(Number(id_or_name))) {
            if (String(id_or_name) !== String(value?.id)) {
              continue;
            } else {
              result = value?.[strMiddleKey]?.value;
              break;
            }
          }
        }

        value = value?.[strMiddleKey];
      }

      if (!Number.isNaN(Number(id_or_name))) {
        if (String(value?.id) === String(id_or_name)) {
          result = value?.value;
          break;
        }
      } else {
        if (String(key) === String(id_or_name)) {
          result = value?.value;
          break;
        }
      }
    }

    return result;
  } catch {
    return {};
  }
}

export function appCategoryGetKey(objCategory, id_or_name) {
  try {
    let result = null;
    let lstCat = Object.entries(objCategory);

    for (let i = 0; i < lstCat.length; i++) {
      let [key, value] = lstCat[i];

      if (key === "id" || key === "value") {
        continue;
      }

      if (!Number.isNaN(Number(id_or_name))) {
        if (String(value?.id) === String(id_or_name)) {
          result = key;
          break;
        }
      } else {
        if (String(key) === String(id_or_name)) {
          result = value?.id;
          break;
        }
      }
    }

    return result;
  } catch {
    return null;
  }
}
//#endregion

export function getSponsorTypeAccordingToTraineeNum(intNumTrainee, fullstack_OR_manager = appVariables.objTrainingToSupport.fullstack.key) {
  const objCat = getCategoryCache();

  if (!fullstack_OR_manager) {
    fullstack_OR_manager = appVariables.objTrainingToSupport.fullstack.key;
  }

  let objTrainingSponsor = objCat?.Root?.toSupport?.[fullstack_OR_manager];
  const objSafeBackupValue = {
    sponser: {
      main: { studentNumber: { value: { eng: 100, arb: 100 } } },
      golden: { studentNumber: { value: { eng: 50, arb: 50 } } },
      silver: { studentNumber: { value: { eng: 25, arb: 25 } } },
      bronze: { studentNumber: { value: { eng: 10, arb: 10 } } },
    },
  };
  if (!objTrainingSponsor) {
    objTrainingSponsor = objSafeBackupValue;
  }

  if (objTrainingSponsor?.blnIsTraining?.value?.eng === "false") {
    if (Number(intNumTrainee) >= Number(objTrainingSponsor?.sponser?.golden?.price?.value?.eng)) {
      return appVariables.objSponserCat.golden.key;
    }

    if (
      Number(intNumTrainee) >= Number(objTrainingSponsor?.sponser?.silver?.price?.value?.eng) &&
      Number(intNumTrainee) < Number(objTrainingSponsor?.sponser?.golden?.price?.value?.eng)
    ) {
      return appVariables.objSponserCat.silver.key;
    }

    if (
      (Number(intNumTrainee) > 0 || Number(intNumTrainee) >= Number(objTrainingSponsor?.sponser?.bronze?.price?.value?.eng)) &&
      Number(intNumTrainee) < Number(objTrainingSponsor?.sponser?.silver?.price?.value?.eng)
    ) {
      return appVariables.objSponserCat.bronze.key;
    }
  } else {
    if (Number(intNumTrainee) >= Number(objTrainingSponsor?.sponser?.main?.studentNumber?.value?.eng)) {
      return appVariables.objSponserCat.main.key;
    }

    if (
      Number(intNumTrainee) >= Number(objTrainingSponsor?.sponser?.golden?.studentNumber?.value?.eng) &&
      Number(intNumTrainee) < Number(objTrainingSponsor?.sponser?.main?.studentNumber?.value?.eng)
    ) {
      return appVariables.objSponserCat.golden.key;
    }

    if (
      Number(intNumTrainee) >= Number(objTrainingSponsor?.sponser?.silver?.studentNumber?.value?.eng) &&
      Number(intNumTrainee) < Number(objTrainingSponsor?.sponser?.golden?.studentNumber?.value?.eng)
    ) {
      return appVariables.objSponserCat.silver.key;
    }

    if (
      (Number(intNumTrainee) > 0 || Number(intNumTrainee) >= Number(objTrainingSponsor?.sponser?.bronze?.studentNumber?.value?.eng)) &&
      Number(intNumTrainee) < Number(objTrainingSponsor?.sponser?.silver?.studentNumber?.value?.eng)
    ) {
      return appVariables.objSponserCat.bronze.key;
    }
  }

  return null;
}

export function getSponsorTypeAccordingToAllTraineeNum(lstSupport = []) {
  const objCoutStudent = {};
  for (let index = 0; index < lstSupport.length; index++) {
    const element = lstSupport[index];
    if (!objCoutStudent?.[element.strTrainingType]) {
      objCoutStudent[element.strTrainingType] = 0;
    }

    if (element?.intSupportTransStatus === appVariables.objSupportTransStatus?.Approved) {
      objCoutStudent[element.strTrainingType] += element?.intTraineesCount || 0;
    }
  }

  const lstValueSort = Object.values(objCoutStudent).sort();
  return getSponsorTypeAccordingToTraineeNum(lstValueSort?.[lstValueSort?.length - 1]);
}

export const isValidUrl = (urlString) => {
  try {
    new URL(urlString);
    return true;
  } catch (error) {
    return false;
  }
};

export const modalOverFllow = (blnModal = false) => {
  if (blnModal) {
    document.getElementById("root").style.overflowY = "hidden";
  } else {
    document.getElementById("root").style.overflowY = "auto";
  }
};

export function uniq_fast(a) {
  var seen = {};
  var out = [];
  var len = a.length;
  var j = 0;
  for (var i = 0; i < len; i++) {
    var item = a[i];
    if (seen[item] !== 1) {
      seen[item] = 1;
      out[j++] = item;
    }
  }
  return out;
}


export const fetchCountry = async () => {
  let countryCode = "kw"
  try {
    const response = await fetch('http://ip-api.com/json/');
    const data = await response.json();
    if (data.status === 'success') {
      countryCode = String(data.countryCode)?.toLowerCase()
    } else {
      console.error('Error fetching country:', data.message);
    }
    return countryCode;
  } catch (error) {
    console.error('Error fetching country:', error);
    return countryCode;
  }
};
