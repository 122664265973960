import React, { useEffect, useState } from "react";
import { BtnGoToTop, ClientDirection, Grid, Modal, ModalBody, Slideshow, Typography } from "@cbmisorg/client-app";

import Header from "./sections/Header";
import Hero from "./sections/Hero";
import ParallaxScrollingApp from "../../../sharedUi/ParallaxScrollingApp";
import Footer from "./sections/Footer";
import OurGoal from "./sections/OurGoal";
import ContactUs from "./sections/ContactUs";
import Aboutus from "./sections/Aboutus";
import Association from "./sections/Association";
import Events from "./sections/Events";
import MessageOfSheikhMuhammad from "./sections/MessageOfSheikhMuhammad";
import useCheckSystemInfo from "../../../../appHelper/appHook/useCheckSystemInfo";
import Partners from "./sections/Partners";
import ITC from "./sections/ITC";
import Achievements from "./sections/Achievements";
import AnnouncementDisplay from "../../../stakeholders/admin/mngAnnouncement/mngAnnouncement.display";
import { App_Light_Color } from "../../../../appHelper/appColor";
import { App_Server_Url_DownloadFiles } from "../../../../appHelper/appVariables";
import { useApp } from "../../../../../client/configuration/contextapi/context";

function Home() {
  const { getNewSystemInfo } = useCheckSystemInfo();

  const { appState, appDispatch } = useApp();
  const lang = appState?.clientInfo?.strLanguage || appState?.clientInfo?.strDataLanguage;

  const [stateInfo, setStateInfo] = useState({ intRefreshCounter: 0, blnOpenAnnouncementModal: false, publicList: [] });
  const { objAnnouncement } = AnnouncementDisplay();

  const closeDrawer = () => {
    ++stateInfo.intRefreshCounter;
    setStateInfo({ ...stateInfo });
  };

  useEffect(() => {
    ClientDirection.setDirection(lang === "arb" ? "rtl" : "ltr");
    appDispatch();
  }, [lang]);

  useEffect(() => {
    intData();
  }, []);

  const intData = async () => {
    if (!appState?.clientInfo?.objSystemInfo || !Object.keys(appState?.clientInfo?.objSystemInfo)?.length) {
      appState.clientInfo.objSystemInfo = await getNewSystemInfo();
      appDispatch();
    }
  };

  useEffect(() => {
    if (objAnnouncement?.lstNotification?.length) {
      const publicList = objAnnouncement?.lstNotification.filter((item) => item?.blnIsPublic);
      if (publicList?.length) {
        stateInfo.publicList = publicList;
        stateInfo.blnOpenAnnouncementModal = true;
        setStateInfo({ ...stateInfo });
      }
    }
  }, [objAnnouncement?.lstNotification]);

  useEffect(() => {
    document.body.classList.remove("paddingBottom");
  }, [])
  
  return (
    <>
      <main>
        <Header intRefreshCounter={stateInfo?.intRefreshCounter} appState={appState} appDispatch={appDispatch} closeDrawer={closeDrawer} />
        <Hero lang={lang} appState={appState}/>
        <Aboutus lang={lang} appState={appState} />
        <Association lang={lang} />
        <OurGoal lang={lang} appState={appState} />
        <MessageOfSheikhMuhammad lang={lang} />

        <ParallaxScrollingApp />

        <ITC lang={lang} appState={appState} />
        <Achievements lang={lang} appState={appState} />

        {!appState?.clientInfo?.objSystemInfo?.lstPartner?.length && appState?.userInfo?.blnIsSponsor? null: (
          <React.Fragment>
            <Partners lang={lang} appState={appState} />
            <ParallaxScrollingApp />
          </React.Fragment>
        )}

        {!appState?.clientInfo?.objSystemInfo?.lstActivities?.length || window.innerWidth <= 768 ? null : (
          <React.Fragment>
            <Events lang={lang} appState={appState} />
          </React.Fragment>
        )}
        <ContactUs appState={appState} lang={lang} />

        <Footer lang={lang} appState={appState} />
      </main>
      <BtnGoToTop className={lang === "arb" ? "arbLang" : ""} />

      <Modal
        open={stateInfo?.blnOpenAnnouncementModal}
        size={"md"}
        eventClose={() => {
          setStateInfo({ ...stateInfo, blnOpenAnnouncementModal: false });
        }}
        className="advModal"
      >
        <ModalBody>
          <Grid container justify={"center"}>
            {stateInfo.publicList?.map((notification) => (
              <Grid item xs="12" key={notification.bigID} p-0>
                <Grid container justify={"center"}>
                  <Grid item xs="12">
                    <Typography as="subtitle2" className="textCenter" color={App_Light_Color}>
                      {notification?.strAnnouncementTitle?.[lang]}
                    </Typography>
                  </Grid>
                  {!notification?.strAnnouncementBody?.[lang] ? null : (
                    <Grid item xs="12">
                      <Typography as="caption" color={App_Light_Color}>
                        {notification?.strAnnouncementBody?.[lang]}
                      </Typography>
                    </Grid>
                  )}
                  {!notification?.lstFile?.length ? null : notification?.lstFile?.length === 1 ? (
                    <Grid item xs="12">
                      <img src={App_Server_Url_DownloadFiles + notification?.lstFile?.[0]?.path} alt={notification?.strAnnouncementTitle?.[lang]} height={"100%"} />
                    </Grid>
                  ) : (
                    <Grid item xs="12">
                      <Slideshow
                        dir={lang === "arb" ? "rtl" : "ltr"}
                        dotsShow={true}
                        autoShow={true}
                        delay={5000}
                        slides={notification?.lstFile?.map((img) => ({
                          image: App_Server_Url_DownloadFiles + img?.path,
                        }))}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </ModalBody>
      </Modal>
    </>
  );
}

export default Home;
