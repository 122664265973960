import { Grid, Paper, Typography } from "@cbmisorg/client-app";
import React from "react";
import { appStyle } from "../../../../../appHelper/appStyle";
import { FormField } from "../../../../../appHelper/formApi/FormAPI";
import { dictionary } from "../../../../../appHelper/appLanguage/dictionary";

const labels = dictionary?.components?.manageSystem;

function RegisterConfig({ fields, appState }) {
  const lang = appState?.clientInfo?.strLanguage;

  return (
    <React.Fragment>
      <Grid container justify={"center"} spacing={3}>
        <Grid item xs="12">
          <Paper className="cardContainer">
            <Grid container spacing={2} py-0>
              <Grid item xs="12">
                <Typography as="subtitle1" color="primary" sx={appStyle?.title1}>
                  {labels?.RegisterConfig?.[lang]}
                </Typography>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs="12">
                <FormField objHandler={fields?.lstContryRegister} icon="public" dir={lang === "arb" ? "rtl" : "ltr"} />
              </Grid>
              <Grid item xs="12">
                <FormField objHandler={fields?.strDefaultContryRegister} icon="public" dir={lang === "arb" ? "rtl" : "ltr"} />
              </Grid>
              <Grid item xs="12">
                <FormField objHandler={fields?.lstContryDetailsRegister} icon="public" dir={lang === "arb" ? "rtl" : "ltr"} />
              </Grid>
              {fields?.lstContryDetailsRegister?.getValue()?.length? (
                <React.Fragment>
                  <Grid item xs="12">
                    <FormField objHandler={fields?.strDetailsAAiotRegister}  dir={lang === "arb" ? "rtl" : "ltr"} />
                  </Grid>
                  <Grid item xs="12">
                    <FormField objHandler={fields?.strDetailsAAiotDefaultContryRegister}  dir={lang === "arb" ? "rtl" : "ltr"} />
                  </Grid>
                  <Grid item xs="12">
                    <FormField objHandler={fields?.strDetailsAAiotRegisterLink} icon="link" dir={lang === "arb" ? "rtl" : "ltr"} />
                  </Grid>
                </React.Fragment>
              ) : null}
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs="12">
          <Paper className="cardContainer">
            <Grid container spacing={2} py-0>
              <Grid item xs="12">
                <Typography as="subtitle1" color="primary" sx={appStyle?.title1}>
                  {labels?.accountConfig?.[lang]}
                </Typography>
              </Grid>
            </Grid>

            <Grid container>     
              <Grid item xs="12">
                <FormField objHandler={fields?.lstContryDetailsAccount} icon="public" dir={lang === "arb" ? "rtl" : "ltr"} />
              </Grid>
              {fields?.lstContryDetailsAccount?.getValue()?.length? (
                <React.Fragment>
                  <Grid item xs="12">
                    <FormField objHandler={fields?.strDetailsAAiotAccount}  dir={lang === "arb" ? "rtl" : "ltr"} />
                  </Grid>
                  <Grid item xs="12">
                    <FormField objHandler={fields?.strDetailsAAiotDefaultContryAccount}  dir={lang === "arb" ? "rtl" : "ltr"} />
                  </Grid>
                  <Grid item xs="12">
                    <FormField objHandler={fields?.strDetailsAAiotAccountLink} icon="link" dir={lang === "arb" ? "rtl" : "ltr"} />
                  </Grid>
                </React.Fragment>
              ) : null}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default RegisterConfig;
