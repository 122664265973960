
//#region public
export const landingPage = "/";
export const sheikProfile= "/sheikProfile";
export const login = "/login";
export const restPassword = "/restPassword"

export const regActivityGuest = "/:activityCode";
export const register = "/register";
export const registration = "/register/:countryCode";
export const trainingRegistration = "/training/:countrycode"
export const page404 = "/404";
export const media = "/:strUrlVedio"

export const initiative = "/fullstack/:strTrainingType";
export const Generate_Certificate="/generatecertificate";
export const ECertificate="/ecertificate/:id";

export const RegForum = "/regForum/:activity/:subject";
export const RegForumToCert = "/regForum/certificate/:activity/:subject";
export const RegNewForum = "/regForum/:ForumCode";
export const ForumBadge = "/forumBadge";

export const surveymonkey = "/surveymonkey/:surveyCode";
export const surveymonkeyWithAnswerID = "/surveymonkey/:surveyCode/:bigAnswerID";
export const surveyThank = "/surveyThank";

//#endregion 

export const account= "/account";
export const supportinfo="/supportinfo";
export const profile="/profile";

//#region admin
export const MngRegForum = "/admin/mngregforum";
export const ManageMedia= "/managemedia";
export const MngUsers= "/mnguser";

export const MngSurvey= "/mngsurvey";
export const MngSurveySave= "/mngsurvey/:type";


//#endregion

