import { Alert } from "@cbmisorg/material";
import * as appFunctions from "../../../util/appFunctions";
import * as appVariables from "../../../configuration/appVariables";
import { appLangDirection, dictionary } from "../../../util/appLanguage/dictionary";
import { generateQueries } from "../../../graphql/schema/GenerateQuery";
import appRouting from "../../../configuration/routing/appRouting";
import { alertStyle } from "../../../util/appStyle";

const strPageInfo = "@src/components/public/login/controller/CtrlLogin.js";
const tblUser = generateQueries("tblUser");

export const CtrlRegistration = ({ appState, appDispatch, isLoading, setIsLoading, countryCode }) => {
  const lang = appState?.clientInfo?.strDataLanguage || appState?.clientInfo?.strLanguage;

  const handler = {
    initData:
      ({ state, setState, isLoading, setIsLoading, navigate, getNewSystemInfo }) =>
      async () => {
        try {
          if (isLoading) {
            return;
          }
          state.blnIsInitDone = true;
          setIsLoading(true);
          if (!appState?.clientInfo?.objSystemInfo?.jsnSystemConfig || JSON?.stringify(appState?.clientInfo?.objSystemInfo?.jsnSystemConfig)?.length < 500) {
            await getNewSystemInfo();
          }
          const jsnSystemConfig = appState?.clientInfo?.objSystemInfo?.jsnSystemConfig;
          if (countryCode && !jsnSystemConfig?.lstContryRegisterCode?.includes(countryCode)) {
            state.countryCode = "";
            navigate(appRouting.page404?.url);
          }
          if (!countryCode) {
            state.countryCode = appState.clientInfo.clientCountryCode ? appState.clientInfo.clientCountryCode : jsnSystemConfig?.strDefaultContryRegisterCode;
          } else {
            state.countryCode = countryCode;
            appState.clientInfo.clientCountryCode = countryCode;
          }

          state.blnDefaultCountry = state.countryCode === jsnSystemConfig?.strDefaultContryRegisterCode;
          setState({ ...state });
          setIsLoading(false);
          appDispatch();
        } catch (error) {
          setIsLoading(false);
          appFunctions.logError(strPageInfo, "initData-ERROR", error);
        }
      },
    handleSubmit: async ({ fields, navigate, state }) => {
      try {
        if (!fields?.controller?.isFormValid()) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "error", appLangDirection?.[lang], alertStyle);
          return;
        }
        if (isLoading) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "warning", appLangDirection?.[lang], alertStyle);
          return;
        }
        setIsLoading(true);
        const objCat = appFunctions.decompressText(appFunctions.getAppLocalStorage()?.clientInfo?.objCatInfo);

        let bigID = appFunctions.generateID(10);

        let countryObj = objCat?.Root?.country?.[appVariables.countryCodeObj?.[countryCode]];

        const countryInfoObj = {};
        if (countryCode && countryObj) {
          countryInfoObj.bigCountryOfResidenceID = countryObj?.id;
          countryInfoObj.bigCountryOfCitizenID = countryObj?.id;
        } else if (!countryCode) {
          countryObj = objCat?.Root?.country?.[appVariables.countryCodeObj?.[appState?.clientInfo?.objSystemInfo?.jsnSystemConfig?.strDefaultContryRegisterCode || "kw"]];
          countryInfoObj.bigCountryOfResidenceID = countryObj?.id;
          countryInfoObj.bigCountryOfCitizenID = countryObj?.id;
        }
        const result = await tblUser(strPageInfo, appState, "aaiotItcUserAdd", {
          objectInput: {
            bigAppID: appVariables.App_ID,
            bigSystemID: appState?.clientInfo?.objSystemInfo?.bigSystemID || appVariables.aaiot_System_ID,
            bigUserID: bigID,

            strUserEmail: fields?.strUserEmail?.getValue(),
            strUserPassword: fields?.strUserPassword?.getValue(),
            strTrainingTypeCode: state?.countryCode,

            jsnUserInfo: {
              strFullName: { eng: fields?.strFullName?.getValue(), arb: fields?.strFullName?.getValue() },
              intUserRoleFK: appVariables?.objUserRole?.Member,
              ...countryInfoObj,
            },
            blnIsManager: false,
            blnIsSponsor: false,
            blnIsStartUp: false,
            blnIsStudent: false,

            lstStatisticFelid: ["intCountJoinUser", "intCountJoinUserAll"],
          },
        });
        if (!result?.blnIsRequestSuccessful) {
          appFunctions.logMessage(result?.response, "");
          if (dictionary?.shared?.alertFetchNote?.[result?.response]) {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.[result?.response]?.[lang], "warning", appLangDirection?.[lang], alertStyle);
          } else {
            Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Save_Record?.[lang], "warning", appLangDirection?.[lang], alertStyle);
          }
          setIsLoading(false);
          return;
        }
        setIsLoading(false);
        appState.clientInfo.blnIsUserLoggedIn = true;
        appState.clientInfo.dtmLastLoggingIn = new Date().toUTCString();
        appState.userInfo = result.response;
        appDispatch();
        fields?.controller?.resetForm();
        navigate(appRouting?.Account?.url, { replace: true });
      } catch (error) {
        appFunctions.logMessage(strPageInfo, "handleSubmit-ERROR", error);
        setIsLoading(false);
      }
    },
  };

  return handler;
};
