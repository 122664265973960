import { Button, Grid, Typography } from '@cbmisorg/material';
import React from 'react'
import { FormField } from '../../../../util/formApi/FormAPI';
import { dictionary } from '../../../../util/appLanguage/dictionary';
import { App_info_Color } from '../../../../util/appStyle';
import { Icon } from '@cbmisorg/icons';

const labels = dictionary?.components?.public?.trainingRegistration;

function Step1({fields,classes, lang, gOToStep2}) {
  return (
    <React.Fragment>
    <Grid container justifyContent="center">
      <Grid item xs="12" justifyContent="center"  className={`${classes?.descriptionLightText}`}>
        <Typography as="body2" className={`${classes?.labelsLight}`}>
          {labels?.details?.[lang]}
        </Typography>
        <Typography as="body2" className={`${classes?.labelsInfo}`} pt-3>
          * {labels?.details2?.[lang]}
        </Typography>
      </Grid>
      <Grid item xs="auto" md="0" className={`${classes?.arrowClose} arrowClose`} >
        <Icon icon={"arrowcircleup"} color={App_info_Color}/>
      </Grid>
    </Grid>
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <Grid container spacing={1}>
        <Grid item className={classes?.subtitle} sx={{sm:{"&before":{ minWidth: "175px !important",}}}}>
          <Typography as={lang === "arb" ? "subtitle1" : "subtitle1"} className={classes?.subtitleLabel}>
            {labels?.jobCategory?.[lang]}
          </Typography>
        </Grid>
        <Grid item xs="12">
          <FormField objHandler={fields?.bigWorkSector2} mode={"classic"} color={App_info_Color} className={classes?.field} />
        </Grid>
      </Grid>

      <Grid container justifyContent={"flex-end"} spacing={1}>
        <Grid item pt-5>
          <Button
            py-0
            px-3
            label={dictionary?.shared?.buttons?.next?.[lang]}
            mode="outlined"
            color={App_info_Color}
            onClick={gOToStep2}
            className={classes?.btn}
          />
        </Grid>
      </Grid>
      <input type="submit" style={{ visibility: "hidden" }} onClick={gOToStep2} />
    </form>
  </React.Fragment>
  )
}

export default Step1