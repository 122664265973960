import { Button, Grid, Typography } from "@cbmisorg/material";
import React from "react";
import { FormField } from "../../../../util/formApi/FormAPI";
import { dictionary } from "../../../../util/appLanguage/dictionary";
import { App_info_Color, App_Light_Color } from "../../../../util/appStyle";
import { workSectorID } from "../../../../configuration/appVariables";

const labels = dictionary?.components?.public?.trainingRegistration;

function Step2({ state, fields, classes, lang, gOToStep3, gOToStepPre, onChangeWorkGovSectorType, appState }) {
  return (
    <React.Fragment>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Grid container spacing={2}>
          <Grid item className={classes?.subtitle}>
            <Typography as={lang === "arb" ? "subtitle1" : "subtitle1"} className={classes?.subtitleLabel}>
              {labels?.internInfo?.[lang]}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormField objHandler={fields.strFullName} icon="person" mode={"classic"} color={App_info_Color} className={classes?.field} />
          </Grid>
          <Grid item xs={12}>
            <FormField objHandler={fields.bigGender} icon="Wc" mode={"classic"} color={App_info_Color} className={classes?.field} />
          </Grid>
          <Grid item xs={appState?.clientInfo?.blnIsUserLoggedIn ? "12" : "0"}>
            <FormField objHandler={fields.strUserEmail} icon="email" mode={"classic"} color={App_info_Color} inputDir="ltr" className={classes?.field} />
          </Grid>
          <Grid item xs={12}>
            <FormField
              objHandler={fields.strUserPhone}
              icon={"phone"}
              endAdornment={String(state.areaCode)}
              mode={"classic"}
              color={App_info_Color}
              inputDir="ltr"
              className={classes?.field}
            />
          </Grid>

          {fields?.bigWorkSector2?.getValue()?.key === workSectorID?.gov ? (
            <React.Fragment>
              <Grid item xs={12} md="6">
                <FormField objHandler={fields.strPositionTitle} icon="work" mode={"classic"} color={App_info_Color} className={classes?.field} />
              </Grid>
              <Grid item xs={12} md="6">
                <FormField
                  objHandler={fields.bigWorkGovSectorType}
                  icon="businesscenter"
                  mode={"classic"}
                  color={App_info_Color}
                  className={classes?.field}
                  onChange={onChangeWorkGovSectorType(fields)}
                />
              </Grid>
              <Grid item xs={12} md="6">
                <FormField objHandler={fields.bigGovPlaceName} icon="business" mode={"classic"} color={App_info_Color} className={classes?.field} />
              </Grid>
              <Grid item xs={12} md="6">
                <FormField objHandler={fields.intExperienceYear} icon="AutoAwesome" mode={"classic"} color={App_info_Color} className={classes?.field} />
              </Grid>
            </React.Fragment>
          ) : fields?.bigWorkSector2?.getValue()?.key === workSectorID?.student ? (
            <React.Fragment>
              <Grid item xs={12} md="12">
                <FormField objHandler={fields.bigCountryOfCitizenID} icon="flag" mode={"classic"} color={App_info_Color} className={classes?.field} />
              </Grid>
              <Grid item xs={12} sm={fields?.bigUniversityName?.getValue()?.key !== "other" ? "12" : "6"}>
                <FormField objHandler={fields.bigUniversityName} icon="accountBalance" mode={"classic"} color={App_info_Color} className={classes?.field} />
              </Grid>
              {fields?.bigUniversityName?.getValue()?.key !== "other" ? null : (
                <Grid item xs={12} sm="6">
                  <FormField objHandler={fields.strUniversityName} icon="accountBalance" mode={"classic"} color={App_info_Color} className={classes?.field} />
                </Grid>
              )}
              <Grid item xs={12} md="6">
                <FormField objHandler={fields.bigMajorID} icon="book" mode={"classic"} color={App_info_Color} className={classes?.field} />
              </Grid>
              <Grid item xs={12} md="6">
                <FormField objHandler={fields.bigAcademicYearID} icon="SquareFoot" mode={"classic"} color={App_info_Color} className={classes?.field} />
              </Grid>
            </React.Fragment>
          ) : fields?.bigWorkSector2?.getValue()?.key === workSectorID?.unEmployee ? (
            <React.Fragment>
              <Grid item xs={12} md="12">
                <FormField objHandler={fields.bigCountryOfCitizenID} icon="flag" mode={"classic"} color={App_info_Color} className={classes?.field} />
              </Grid>
              <Grid item xs={12} sm={fields?.bigUniversityName?.getValue()?.key !== "other" ? "12" : "6"}>
                <FormField objHandler={fields.bigUniversityName} icon="accountBalance" mode={"classic"} color={App_info_Color} className={classes?.field} />
              </Grid>
              {fields?.bigUniversityName?.getValue()?.key !== "other" ? null : (
                <Grid item xs={12} sm="6">
                  <FormField objHandler={fields.strUniversityName} icon="accountBalance" mode={"classic"} color={App_info_Color} className={classes?.field} />
                </Grid>
              )}
              <Grid item xs={12} md="6">
                <FormField objHandler={fields.bigMajorID} icon="book" mode={"classic"} color={App_info_Color} className={classes?.field} />
              </Grid>
              <Grid item xs={12} md="6">
                <FormField objHandler={fields.strYearGraduation} icon="school" mode={"classic"} color={App_info_Color} className={classes?.field} />
              </Grid>
            </React.Fragment>
          ) : fields?.bigWorkSector2?.getValue()?.key === workSectorID?.prvt ? (
            <React.Fragment>
              <Grid item xs={12} md="12">
                <FormField objHandler={fields.bigCountryOfCitizenID} icon="flag" mode={"classic"} color={App_info_Color} className={classes?.field} />
              </Grid>
              <Grid item xs={12} md="6">
                <FormField objHandler={fields.strPositionTitle} icon="work" mode={"classic"} color={App_info_Color} className={classes?.field} />
              </Grid>
              <Grid item xs={12} md="6">
                <FormField objHandler={fields.bigMajorID} icon="book" mode={"classic"} color={App_info_Color} className={classes?.field} />
              </Grid>
              <Grid item xs={12} md="6">
                <FormField objHandler={fields.strPlaceName} icon="business" mode={"classic"} color={App_info_Color} className={classes?.field} />
              </Grid>
              <Grid item xs={12} md="6">
                <FormField objHandler={fields.intExperienceYear} icon="AutoAwesome" mode={"classic"} color={App_info_Color} className={classes?.field} />
              </Grid>
            </React.Fragment>
          ) : null}

          {appState?.clientInfo?.blnIsUserLoggedIn ? null : (
            <React.Fragment>
              <Grid item className={classes?.subtitle}>
                <Typography as={lang === "arb" ? "subtitle1" : "subtitle1"} className={classes?.subtitleLabel}>
                  {labels?.registrationInfo?.[lang]}
                </Typography>
              </Grid>
              <Grid item xs="12">
                <FormField objHandler={fields.strUserEmail} icon="email" mode={"classic"} color={App_info_Color} inputDir="ltr" className={classes?.field} />
              </Grid>
              <Grid item xs={12} md="6">
                <FormField objHandler={fields.strUserPassword} icon="vpnkey" mode={"classic"} color={App_info_Color} className={classes?.field} />
              </Grid>
              <Grid item xs={12} md="6">
                <FormField objHandler={fields.strConfirmPassword} icon="vpnkey" mode={"classic"} color={App_info_Color} className={classes?.field} />
              </Grid>
            </React.Fragment>
          )}
        </Grid>

        <Grid container justifyContent={"space-between"} spacing={2}>
          <Grid item py-5>
            <Button py-0 px-3 label={dictionary?.shared?.buttons?.previous?.[lang]} mode="outlined" color={App_Light_Color} onClick={gOToStepPre} className={classes?.btn} />
          </Grid>
          <Grid item py-5>
            <Button py-0 px-3 label={dictionary?.shared?.buttons?.next?.[lang]} mode="outlined" color={App_info_Color} onClick={gOToStep3} className={classes?.btn} />
          </Grid>
        </Grid>
        <input type="submit" style={{ visibility: "hidden" }} onClick={gOToStep3} />
      </form>
    </React.Fragment>
  );
}

export default Step2;
