import React, { useEffect, useState } from "react";
import { useApp } from "../../../configuration/contextapi/context";
import regForumCtrl from "./regForum.controller";
import { Button, Container, Grid, Loader, Paper, Typography } from "@cbmisorg/material";
import { App_info_Color, App_Light_Text_Color } from "../../../util/appStyle";
import ShredHeader from "../../shared/shredHeader";
import { dictionary } from "../../../util/appLanguage/dictionary";
import { ClassesBuilder } from "@cbmisorg/styles";
import { objStyle } from "./regForum.style";
import { FormData, FormField } from "../../../util/formApi/FormAPI";
import { Icon } from "@cbmisorg/icons";
import useCheckHooksGlb from "../../../util/hooks/useCheckHooksGlb";
import useCheckSystemInfo from "../../../util/hooks/useCheckSystemInfo";
import { useNavigate, useParams } from "@cbmisorg/router";
import useCheckCat from "../../../util/hooks/useCheckCat";
import { App_Server_Url_DownloadFiles } from "../../../configuration/appVariables";
import * as appFunctions from "../../../util/appFunctions";
import lineImage from "../../../util/assets/images/Mediamodifier-Design.svg";

export default function RegForumView() {
  useCheckHooksGlb();
  const { appState, appDispatch } = useApp();
  const { getNewSystemInfo } = useCheckSystemInfo({ appState, notHook: true });
  const { getNewCatInfo } = useCheckCat({ appState, blnIsNotHook: true });
  const lang = appState?.clientInfo?.strLanguage || "arb";
  const labels = dictionary?.components?.public?.regFormActivity;
  const classes = ClassesBuilder(objStyle, { lang });
  // const jsnSystemConfig = appState?.clientInfo?.objSystemInfo?.jsnSystemConfig;

  const navigate = useNavigate();
  const param = useParams();
  const activity = param?.activity;
  const subject = param?.subject;

  const [state, setState] = useState({
    blnIsInitDone: false,
    bigSubjectID: null,
    bigActivityID: null,
    strSubjectKey: "",
    strActivityKey: "",
    objForumInfo: {},
    objSubForumInfo: {},
    lstBrochureFile: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const fields = FormData({
    strFullName: {
      control: "text",
      blnLanguage: false,
      validations: { required: () => !appState?.clientInfo?.blnIsUserLoggedIn },
    },
    strUserEmail: {
      control: "text",
      blnLanguage: false,
      validations: { required: () => true, email: true },
    },
    strUserPassword: {
      control: "password",
      blnLanguage: false,
      validations: {
        required: () => !appState?.clientInfo?.blnIsUserLoggedIn,
        minLength: () => (!appState?.clientInfo?.blnIsUserLoggedIn ? 4 : null),
      },
    },
    strConfirmPassword: {
      control: "password",
      blnLanguage: false,
      validations: {
        required: () => !appState?.clientInfo?.blnIsUserLoggedIn,
        minLength: () => (!appState?.clientInfo?.blnIsUserLoggedIn ? 4 : null),
        equal: () => (!appState?.clientInfo?.blnIsUserLoggedIn ? "strUserPassword" : null),
      },
    },
  });

  const handlers = regForumCtrl({ appDispatch, appState });
  const initData = handlers.initData({ isLoading, setIsLoading, setState, state, navigate, getNewSystemInfo, getNewCatInfo, activity, subject, fields });
  const saveActivityGuestInToDB = handlers.saveActivityGuestInToDB({ isLoading, setIsLoading, setState, state, navigate, fields });

  useEffect(() => {
    document.body.classList.add("paddingBottom");
    if (!state?.blnIsInitDone) {
      initData();
    }
  }, []);

  return (
    <>
      <ShredHeader title={labels?.title?.[lang]} lang={lang} blnHome={false} blnBack={false} blnLang={false} blnLogout={false} sx={{ position: "absolute" }} />
      {!isLoading ? null : <Loader backdrop={true} color={App_info_Color} />}
      <section className={classes?.secDark} >
        <Container className={classes?.mainContainer}>
          <Grid container justifyContent={"center"} alignSelf="center">
            <Grid item xs="12" md="9" lg="7" xl="6" className={classes?.bgBox}>
              <Container className={classes?.container} pt-10>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item className={classes?.subtitle}>
                      <Typography as={lang === "arb" ? "subtitle1" : "subtitle2"} className={classes?.subtitleLabel}>
                        {labels?.registrationInfo?.[lang]}
                      </Typography>
                    </Grid>
                    {appState?.clientInfo?.blnIsUserLoggedIn ? null : (
                      <Grid item xs={12} pt-0>
                        <FormField objHandler={fields.strFullName} icon="AccountCircle" mode={"classic"} color={App_info_Color} className={classes?.field} />
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <FormField objHandler={fields.strUserEmail} icon="email" mode={"classic"} color={App_info_Color} inputDir="ltr" className={classes?.field} />
                    </Grid>

                    {appState?.clientInfo?.blnIsUserLoggedIn ? null : (
                      <>
                        <Grid item xs={12} md="6">
                          <FormField objHandler={fields.strUserPassword} icon="vpnkey" mode={"classic"} color={App_info_Color} className={classes?.field} />
                        </Grid>
                        <Grid item xs={12} md="6">
                          <FormField objHandler={fields.strConfirmPassword} icon="vpnkey" mode={"classic"} color={App_info_Color} className={classes?.field} />
                        </Grid>
                      </>
                    )}
                  </Grid>

                  <Grid container justifyContent={"center"} spacing={2}>
                    <Grid item xs="12" pt-5 pb-10>
                      <Button py-0 px-10 label={labels?.btn?.[lang]} mode="outlined" color={App_info_Color} onClick={saveActivityGuestInToDB} className={classes?.btn} />
                    </Grid>
                  </Grid>
                  <input type="submit" style={{ visibility: "hidden" }} onClick={saveActivityGuestInToDB} />
                </form>
              </Container>
            </Grid>
          </Grid>
        </Container>
      </section>
    </>
  );
}
