import { Alert } from "@cbmisorg/material";
import * as appFunctions from "../../../util/appFunctions";
import * as appVariables from "../../../configuration/appVariables";
import { appLangDirection, dictionary } from "../../../util/appLanguage/dictionary";
import { generateQueries } from "../../../graphql/schema/GenerateQuery";
import appRouting from "../../../configuration/routing/appRouting";
import { alertStyle } from "../../../util/appStyle";

const strPageInfo = "@src/client/component/public/trainingRegistration/trainingRegistration.ctrl.js";
const tblUser = generateQueries("tblUser");

export const CtrlTrainingRegistration = ({ appState, appDispatch, isLoading, setIsLoading, countryCode, fields }) => {
  const lang = appState?.clientInfo?.strDataLanguage || appState?.clientInfo?.strLanguage;

  const handler = {
    initData:
      ({ isLoading, setIsLoading, setState, state, navigate, getNewSystemInfo, getNewCatInfo, fields, interviewfields, lang }) =>
      async () => {
        try {
          if (isLoading) {
            return;
          }
          state.blnIsInitDone = true;
          setIsLoading(true);
          if (!appState?.clientInfo?.objSystemInfo?.jsnSystemConfig || JSON?.stringify(appState?.clientInfo?.objSystemInfo?.jsnSystemConfig)?.length < 500) {
            await getNewSystemInfo();
          }
          if (!appState?.clientInfo?.objCatInfo || String(appState?.clientInfo?.objCatInfo)?.length < 500) {
            await getNewCatInfo();
          }
          const jsnSystemConfig = appState?.clientInfo?.objSystemInfo?.jsnSystemConfig;
          if (countryCode && !jsnSystemConfig?.lstContryRegisterCode?.includes(countryCode)) {
            state.countryCode = "";
            navigate(appRouting.page404?.url);
          }
          if (!countryCode) {
            state.countryCode = appState.clientInfo.clientCountryCode ? appState.clientInfo.clientCountryCode : jsnSystemConfig?.strDefaultContryRegisterCode;
          } else {
            state.countryCode = countryCode;
            appState.clientInfo.clientCountryCode = countryCode;
          }

          state.blnDefaultCountry = state.countryCode === jsnSystemConfig?.strDefaultContryRegisterCode;

          //#endregion set options
          const objCat = appFunctions.decompressText(appFunctions.getAppLocalStorage()?.clientInfo?.objCatInfo);
          let lstCountryOption = [];
          let lstUniversityOption = [];
          let lstMajorOption = [];
          let lstAcademicYearOption = [];
          let lstGenderOption = [];
          let lstTypeGovSector = [];

          Object.entries(objCat?.Root?.country || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value" && value?.code?.value?.eng === state.countryCode) {
              lstCountryOption.push({ key: value?.id, value: value?.nationality?.value });
              Object.entries(value?.university || {}).forEach(([key, value]) => {
                if (key !== "id" && key !== "value") {
                  lstUniversityOption.push({ key: value?.id, value: value?.value });
                }
              });
              lstUniversityOption.push({ key: "other", value: dictionary?.shared?.buttons?.otherBtn });
              state.areaCode = lang === "arb" ? `${value?.phoneCode?.value?.eng}+` : `+${value?.phoneCode?.value?.eng}`;
              return;
            }
          });
          Object.entries(objCat?.Root?.major || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstMajorOption.push({ key: value?.id, value: value?.value });
            }
          });
          Object.entries(objCat?.Root?.academicYear || {}).forEach(([key, value], index) => {
            if (key !== "id" && key !== "value" && index < 6) {
              lstAcademicYearOption.push({ key: value?.id, value: value?.value });
            }
          });
          Object.entries(objCat?.Root?.gender || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstGenderOption.push({ key: value?.id, value: value?.value });
            }
          });
          Object.entries(objCat?.Root?.typeGovSector || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstTypeGovSector.push({ key: value?.id, value: value?.value });
            }
          });

          fields.bigMajorID.setOptions([...lstMajorOption, { key: "other", value: dictionary?.shared?.buttons?.otherBtn }]);
          fields.bigGender.setOptions(lstGenderOption);
          fields.bigCountryOfCitizenID.setOptions([...lstCountryOption, { key: "other", value: dictionary?.shared?.buttons?.otherBtn }]);
          fields.bigUniversityName.setOptions([...lstUniversityOption], true);
          fields.bigAcademicYearID.setOptions([...lstAcademicYearOption]);
          fields.bigWorkGovSectorType.setOptions([...lstTypeGovSector]);
          interviewfields.bigWorkGovSectorType.setOptions([...lstTypeGovSector]);
          //#endregion

          if (!appState?.clientInfo?.blnIsUserLoggedIn) {
            fields?.strUserPassword?.setValidation("required", true, false);
            fields?.strConfirmPassword?.setValidation("required", true, false);
          }

          //#region setValues
          if (appState?.userInfo?.jsnUserInfo?.strFullName) {
            if (typeof appState?.userInfo?.jsnUserInfo?.strFullName === "object" && appState?.userInfo?.jsnUserInfo?.strFullName?.[lang]) {
              fields.strFullName.setValue(appState?.userInfo?.jsnUserInfo?.strFullName?.[lang]);
            } else if(typeof appState?.userInfo?.jsnUserInfo?.strFullName === "string"){
              fields.strFullName.setValue(appState?.userInfo?.jsnUserInfo?.strFullName);
            }
          }
          if (appState?.userInfo?.jsnUserInfo?.strMajor) {
            fields.bigMajorID.setValue(appState?.userInfo?.jsnUserInfo?.strMajor);
          }
          if (appState?.userInfo?.jsnUserInfo?.bigGender) {
            fields.bigGender.setValue(appState?.userInfo?.jsnUserInfo?.bigGender);
          }
          if (appState?.userInfo?.jsnUserInfo?.bigWorkSector) {
            fields.bigWorkSector2.setValue(appState?.userInfo?.jsnUserInfo?.bigWorkSector);
          }
          if (appState?.userInfo?.strUserEmail) {
            fields.strUserEmail.setValue(appState?.userInfo?.strUserEmail);
          }
          if (appState?.userInfo?.strUserPhone) {
            fields.strUserPhone.setValue(appState?.userInfo?.strUserPhone);
          }
          if (appState?.userInfo?.jsnUserInfo?.bigWorkGovSectorType) {
            fields.bigWorkGovSectorType.setValue(appState?.userInfo?.jsnUserInfo?.bigWorkGovSectorType);
            handler.onChangeWorkGovSectorType(fields)();
          }
          if (appState?.userInfo?.jsnUserInfo?.bigGovPlaceName) {
            fields.bigGovPlaceName.setValue(appState?.userInfo?.jsnUserInfo?.bigGovPlaceName);
          }
          if (appState?.userInfo?.jsnUserInfo?.bigCountryOfCitizenID) {
            if (String(lstCountryOption?.[0]?.key) === String(appState?.userInfo?.jsnUserInfo?.bigCountryOfCitizenID)) {
              fields.bigCountryOfCitizenID.setValue(appState?.userInfo?.jsnUserInfo?.bigCountryOfCitizenID);
            } else {
              fields.bigCountryOfCitizenID.setValue("other");
            }
          }
          if (appState?.userInfo?.jsnUserInfo?.strPositionTitle) {
            fields.strPositionTitle.setValue(appState?.userInfo?.jsnUserInfo?.strPositionTitle);
          }
          if (appState?.userInfo?.jsnUserInfo?.bigMajorID) {
            fields.bigMajorID.setValue(appState?.userInfo?.jsnUserInfo?.bigMajorID);
          }
          if (appState?.userInfo?.jsnUserInfo?.bigUniversityName) {
            fields.bigUniversityName.setValue(appState?.userInfo?.jsnUserInfo?.bigUniversityName);
          }
          if (appState?.userInfo?.jsnUserInfo?.strUniversityName) {
            fields.strUniversityName.setValue(appState?.userInfo?.jsnUserInfo?.strUniversityName);
          }
          if (appState?.userInfo?.jsnUserInfo?.bigAcademicYearID) {
            fields.bigAcademicYearID.setValue(appState?.userInfo?.jsnUserInfo?.bigAcademicYearID);
          }
          if (appState?.userInfo?.jsnUserInfo?.strYearGraduation) {
            fields.strYearGraduation.setValue(appState?.userInfo?.jsnUserInfo?.strYearGraduation);
          }
          if (appState?.userInfo?.jsnUserInfo?.strPlaceName) {
            fields.strPlaceName.setValue(appState?.userInfo?.jsnUserInfo?.strPlaceName);
          }
          if (appState?.userInfo?.jsnUserInfo?.intExperienceYear) {
            fields.intExperienceYear.setValue(appState?.userInfo?.jsnUserInfo?.intExperienceYear);
          }

          if (typeof appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnAppsNeedDev === "boolean") {
            interviewfields.blnAppsNeedDev.setValue(String(appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnAppsNeedDev));
          }
          if (appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExplainProposal) {
            interviewfields.strExplainProposal.setValue(appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExplainProposal);
          }
          if (typeof appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnlikeWorkinGivSec === "boolean") {
            interviewfields.blnlikeWorkinGivSec.setValue(String(appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnlikeWorkinGivSec));
          }
          if (appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.bigWorkGovSectorType) {
            interviewfields.bigWorkGovSectorType.setValue(appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.bigWorkGovSectorType);
          }
          if (appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.bigGovPlaceName) {
            interviewfields.bigGovPlaceName.setValue(appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.bigGovPlaceName);
          }
          if (typeof appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnGraduationProject === "boolean") {
            interviewfields.blnGraduationProject.setValue(String(appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnGraduationProject));
          }
          if (typeof appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnHaveGoveIdea === "boolean") {
            interviewfields.blnHaveGoveIdea.setValue(String(appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnHaveGoveIdea));
          }
          if (appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExplainIdea) {
            interviewfields.strExplainIdea.setValue(appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExplainIdea);
          }
          if (typeof appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnExpJs === "boolean") {
            interviewfields.blnExpJs.setValue(String(appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnExpJs));
          }
          if (appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.intYearExpJs) {
            interviewfields.intYearExpJs.setValue(appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.intYearExpJs);
          }
          if (appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.intNumJsProject) {
            interviewfields.intNumJsProject.setValue(appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.intNumJsProject);
          }
          if (appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExpJsProject) {
            interviewfields.strExpJsProject.setValue(appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExpJsProject);
          }
          if (typeof appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnExFe === "boolean") {
            interviewfields.blnExFe.setValue(String(appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnExFe));
          }
          if (appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.intYearExpFn) {
            interviewfields.intYearExpFn.setValue(appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.intYearExpFn);
          }
          if (appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.intYearExpNode) {
            interviewfields.intYearExpNode.setValue(appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.intYearExpNode);
          }
          if (typeof appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnExpNode === "boolean") {
            interviewfields.blnExpNode.setValue(String(appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnExpNode));
          }
          if (appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.intYearExpJs) {
            interviewfields.intYearExpJs.setValue(appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.intYearExpJs);
          }
          if (appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.intNumNodeProject) {
            interviewfields.intNumNodeProject.setValue(appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.intNumNodeProject);
          }
          if (appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExpNodeProject) {
            interviewfields.strExpNodeProject.setValue(appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExpNodeProject);
          }
          if (typeof appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnExAi === "boolean") {
            interviewfields.blnExAi.setValue(String(appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnExAi));
          }
          if (appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.intYearExpAi) {
            interviewfields.intYearExpAi.setValue(appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.intYearExpAi);
          }
          if (appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExpAiProject) {
            interviewfields.strExpAiProject.setValue(appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExpAiProject);
          }
          if (typeof appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnExCybS === "boolean") {
            interviewfields.blnExCybS.setValue(String(appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnExCybS));
          }
          if (appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.intYearExpCybS) {
            interviewfields.intYearExpCybS.setValue(appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.intYearExpCybS);
          }
          if (appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExpCybSProject) {
            interviewfields.strExpCybSProject.setValue(appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExpCybSProject);
          }
          // if (typeof appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnExMobile === "boolean") {
          //   interviewfields.blnExMobile.setValue(String(appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.blnExMobile));
          // }
          // if (appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.intYearExpMobile) {
          //   interviewfields.intYearExpMobile.setValue(appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.intYearExpMobile);
          // }
          // if (appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExpMobileProject) {
          //   interviewfields.strExpMobileProject.setValue(appState?.userInfo?.jsnUserInfo?.jsnInterviewInfo?.strExpMobileProject);
          // }
          //#endregion
          appState.clientInfo.strLanguage = lang || "arb"
          setState({ ...state });
          setIsLoading(false);
          appDispatch();
        } catch (error) {
          setIsLoading(false);
          appFunctions.logError(strPageInfo, "initData-ERROR", error);
        }
      },

    getUserObj: ({ fields, interviewfields, state }) => {
      const objCat = appFunctions.decompressText(appFunctions.getAppLocalStorage()?.clientInfo?.objCatInfo);
      let bigID = appFunctions.generateID(10);
      let countryObj = objCat?.Root?.country?.[appVariables.countryCodeObj?.[countryCode]];
      const countryInfoObj = {};
      if (fields?.bigCountryOfCitizenID?.getValue()?.key) {
        const countryID = fields?.bigCountryOfCitizenID?.getValue()?.key;
        const getCountryKey = appFunctions.appCategoryGetKey(objCat?.Root?.country, countryID);
        state.countryCode = objCat?.Root?.country?.[getCountryKey]?.code?.value?.["eng"];
        countryInfoObj.bigCountryOfResidenceID = countryID;
        countryInfoObj.bigCountryOfCitizenID = countryID;
      } else if (countryCode && countryObj) {
        countryInfoObj.bigCountryOfResidenceID = countryObj?.id;
        countryInfoObj.bigCountryOfCitizenID = countryObj?.id;
      } else if (!countryCode) {
        countryObj = objCat?.Root?.country?.[appVariables.countryCodeObj?.[appState?.clientInfo?.objSystemInfo?.jsnSystemConfig?.strDefaultContryRegisterCode || "kw"]];
        countryInfoObj.bigCountryOfResidenceID = countryObj?.id;
        countryInfoObj.bigCountryOfCitizenID = countryObj?.id;
      }
      const objectInput = {
        ...(appState?.userInfo ? appState?.userInfo : {}),
        bigUserID: appState?.userInfo?.bigUserID ? appState?.userInfo?.bigUserID : bigID,
        bigAppID: appVariables.App_ID,
        bigSystemID: appState?.clientInfo?.objSystemInfo?.bigSystemID || appVariables.aaiot_System_ID,

        strUserEmail: fields?.strUserEmail?.getValue(),
        strUserPhone: fields?.strUserPhone?.getValue(),
        strTrainingTypeCode: state?.countryCode,

        jsnUserInfo: {
          ...(appState?.userInfo?.jsnUserInfo ? appState?.userInfo?.jsnUserInfo : {}),
          // strFullName: { eng: fields?.strFullName?.getValue() || "", arb: fields?.strFullName?.getValue() || "" },
          strFullName: fields?.strFullName?.getValue(),
          intUserRoleFK: appState?.userInfo?.jsnUserInfo?.intUserRoleFK ? appState?.userInfo?.jsnUserInfo?.intUserRoleFK : appVariables?.objUserRole?.Member,
          bigWorkGovSectorType: fields?.bigWorkGovSectorType?.getValue()?.key || "",
          bigGovPlaceName: fields?.bigGovPlaceName?.getValue()?.key || "",
          bigWorkSector: fields?.bigWorkSector2?.getValue()?.key,
          strMajor: fields?.bigMajorID?.getValue()?.key,
          strPositionTitle: fields?.strPositionTitle?.getValue(),
          bigGender: fields?.bigGender?.getValue()?.key,
          bigAcademicYearID: fields?.bigAcademicYearID?.getValue()?.key,
          strYearGraduation: fields?.strYearGraduation?.getValue(),
          strPlaceName: fields?.strPlaceName?.getValue(),
          intExperienceYear: fields?.intExperienceYear?.getValue(),
          bigUniversityName: fields?.bigUniversityName?.getValue()?.key,
          strUniversityName: fields?.strUniversityName?.getValue(),
          ...countryInfoObj,
          jsnInterviewInfo: {
            blnAppsNeedDev: interviewfields?.blnAppsNeedDev?.getValue() ? interviewfields?.blnAppsNeedDev?.getValue()?.key === "true" : "",
            strExplainProposal: interviewfields?.strExplainProposal?.getValue(),
            blnlikeWorkinGivSec: interviewfields?.blnlikeWorkinGivSec?.getValue() ? interviewfields?.blnlikeWorkinGivSec?.getValue()?.key === "true" : "",
            bigWorkGovSectorType: interviewfields?.bigWorkGovSectorType?.getValue()?.key,
            bigGovPlaceName: interviewfields?.bigGovPlaceName?.getValue()?.key,
            blnGraduationProject: interviewfields?.blnGraduationProject?.getValue() ? interviewfields?.blnGraduationProject?.getValue()?.key === "true" : "",
            blnHaveGoveIdea: interviewfields?.blnHaveGoveIdea?.getValue() ? interviewfields?.blnHaveGoveIdea?.getValue()?.key === "true" : "",
            strExplainIdea: interviewfields?.strExplainIdea?.getValue() ? interviewfields?.strExplainIdea?.getValue()?.key === "true" : "",
            blnExpJs: interviewfields?.blnExpJs?.getValue() ? interviewfields?.blnExpJs?.getValue()?.key === "true" : "",
            intYearExpJs: interviewfields?.intYearExpJs?.getValue(),
            intNumJsProject: interviewfields?.intNumJsProject?.getValue(),
            strExpJsProject: interviewfields?.strExpJsProject?.getValue(),
            blnExFe: interviewfields?.blnExFe?.getValue() ? interviewfields?.blnExFe?.getValue()?.key === "true" : "",
            intYearExpFn: interviewfields?.intYearExpFn?.getValue(),
            strExpFeProject: interviewfields?.strExpFeProject?.getValue(),
            blnExpNode: interviewfields?.blnExpNode?.getValue() ? interviewfields?.blnExpNode?.getValue()?.key === "true" : "",
            intYearExpNode: interviewfields?.intYearExpNode?.getValue(),
            intNumNodeProject: interviewfields?.intNumNodeProject?.getValue(),
            strExpNodeProject: interviewfields?.strExpNodeProject?.getValue(),
            blnExAi: interviewfields?.blnExAi?.getValue() ? interviewfields?.blnExAi?.getValue()?.key === "true" : "",
            intYearExpAi: interviewfields?.intYearExpAi?.getValue(),
            strExpAiProject: interviewfields?.strExpAiProject?.getValue(),
            blnExCybS: interviewfields?.blnExCybS?.getValue() ? interviewfields?.blnExCybS?.getValue()?.key === "true" : "",
            intYearExpCybS: interviewfields?.intYearExpCybS?.getValue(),
            strExpCybSProject: interviewfields?.strExpCybSProject?.getValue(),
            // blnExMobile: interviewfields?.blnExMobile?.getValue() ? interviewfields?.blnExMobile?.getValue()?.key === "true" : "",
            // intYearExpMobile: interviewfields?.intYearExpMobile?.getValue(),
            // strExpMobileProject: interviewfields?.strExpMobileProject?.getValue(),
          },
          dtmRegisterStudent: appFunctions?.getDateUSFormat(appFunctions.getCurrentDate()),
        },
      };

      return objectInput;
    },
    gOToStep2:
      ({ state, setState, fields, interviewfields }) =>
      () => {
        const bigWorkSectorId = fields?.bigWorkSector2?.getValue()?.key || 0;
        if (bigWorkSectorId) {
          fields?.strPositionTitle?.setValidation("required", false, false);
          fields?.bigWorkGovSectorType?.setValidation("required", false, false);
          fields?.bigGovPlaceName?.setValidation("required", false, false);
          fields?.intExperienceYear?.setValidation("required", false, false);
          fields?.bigCountryOfCitizenID?.setValidation("required", false, false);
          fields?.bigUniversityName?.setValidation("required", false, false);
          fields?.strUniversityName?.setValidation("required", false, false);
          fields?.bigMajorID?.setValidation("required", false, false);
          fields?.bigAcademicYearID?.setValidation("required", false, false);
          fields?.strYearGraduation?.setValidation("required", false, false);
          fields?.strPlaceName?.setValidation("required", false, false);

          fields?.strPositionTitle?.setError("");
          fields?.bigWorkGovSectorType?.setError("");
          fields?.bigGovPlaceName?.setError("");
          fields?.intExperienceYear?.setError("");
          fields?.bigCountryOfCitizenID?.setError("");
          fields?.bigUniversityName?.setError("");
          fields?.strUniversityName?.setError("");
          fields?.bigMajorID?.setError("");
          fields?.bigAcademicYearID?.setError("");
          fields?.strYearGraduation?.setError("");
          fields?.strPlaceName?.setError("");
          switch (bigWorkSectorId) {
            case appVariables?.workSectorID?.gov:
              fields?.strPositionTitle?.setValidation("required", true, false);
              fields?.bigWorkGovSectorType?.setValidation("required", true, false);
              fields?.bigGovPlaceName?.setValidation("required", true, false);
              fields?.intExperienceYear?.setValidation("required", true, false);
              break;
            case appVariables?.workSectorID?.student:
              fields?.bigCountryOfCitizenID?.setValidation("required", true, false);
              fields?.bigUniversityName?.setValidation("required", true, false);
              fields?.bigMajorID?.setValidation("required", true, false);
              fields?.bigAcademicYearID?.setValidation("required", true, false);
              break;
            case appVariables?.workSectorID?.unEmployee:
              fields?.bigCountryOfCitizenID?.setValidation("required", true, false);
              fields?.bigUniversityName?.setValidation("required", true, false);
              fields?.bigMajorID?.setValidation("required", true, false);
              fields?.strYearGraduation?.setValidation("required", true, false);
              break;
            case appVariables?.workSectorID?.prvt:
              fields?.bigCountryOfCitizenID?.setValidation("required", true, false);
              fields?.strPositionTitle?.setValidation("required", true, false);
              fields?.strPlaceName?.setValidation("required", true, false);
              fields?.intExperienceYear?.setValidation("required", true, false);
              break;
            default:
              break;
          }
          setState({ ...state, intStep: 2 });
        } else {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "error", appLangDirection?.[lang], alertStyle);
          return;
        }
        appState.userInfo = handler?.getUserObj({ fields, interviewfields, state });
        appDispatch();
      },

    gOToStep3:
      ({ state, setState, fields, interviewfields }) =>
      () => {
        if (fields?.bigUniversityName?.getValue()?.key === "other") {
          fields?.strUniversityName?.setValidation("required", true, false);
        } else {
          fields?.strUniversityName?.setValidation("required", false, false);
          fields?.strUniversityName?.setError("");
        }

        if (!fields?.controller?.isFormValid()) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "error", appLangDirection?.[lang], alertStyle);
          return;
        }
        setState({ ...state, intStep: 3 });
        appState.userInfo = handler?.getUserObj({ fields, interviewfields, state });
        appDispatch();
      },
    gOToStepPre:
      ({ fields, interviewfields, state, setState }) =>
      () => {
        setState({ ...state, intStep: state.intStep - 1 });
        appState.userInfo = handler?.getUserObj({ fields, interviewfields, state });
        appDispatch();
      },

    onChangeWorkGovSectorType: (fields) => () => {
      try {
        //#endregion set options
        const objCat = appFunctions.decompressText(appFunctions.getAppLocalStorage()?.clientInfo?.objCatInfo);
        const workGovSectorTypeid = fields.bigWorkGovSectorType.getValue()?.key || "";
        if (!workGovSectorTypeid) return;

        const workGovSectorTypeKey = appFunctions?.appCategoryGetKey(objCat?.Root?.typeGovSector, workGovSectorTypeid);
        let lstGovPlaceName = [];
        Object.entries(objCat?.Root?.typeGovSector?.[workGovSectorTypeKey] || {}).forEach(([key, value]) => {
          if (key !== "id" && key !== "value") {
            lstGovPlaceName.push({ key: value?.id, value: value?.value });
          }
        });

        fields.bigGovPlaceName.setOptions([...lstGovPlaceName, { key: "other", value: dictionary?.shared?.buttons?.otherBtn }], true);

        //#endregion

        //#region setValues
        if (appState?.userInfo?.jsnUserInfo?.strFullName) {
          fields.strFullName.setValue(appState?.userInfo?.jsnUserInfo?.strFullName?.[lang] || appState?.userInfo?.jsnUserInfo?.strFullName);
        }
        if (appState?.userInfo?.jsnUserInfo?.strMajor) {
          fields.bigMajorID.setValue(appState?.userInfo?.jsnUserInfo?.strMajor);
        }
        if (appState?.userInfo?.jsnUserInfo?.bigGender) {
          fields.bigGender.setValue(appState?.userInfo?.jsnUserInfo?.bigGender);
        }
        if (appState?.userInfo?.jsnUserInfo?.bigWorkSector) {
          fields.bigWorkSector2.setValue(appState?.userInfo?.jsnUserInfo?.bigWorkSector);
        }
        //#endregion

        setIsLoading(false);
        appDispatch();
      } catch (error) {
        setIsLoading(false);
        appFunctions.logError(strPageInfo, "initData-ERROR", error);
      }
    },

    onChangesetValidation:
      ({ fields, interviewfields, state }) =>
      (mainfieldName, lstfieldsName = [], lstNotfieldsName = []) =>
      () => {
        const blnValue = interviewfields?.[mainfieldName]?.getValue()?.key === "true";

        for (let index = 0; index < lstfieldsName.length; index++) {
          if (blnValue) {
            interviewfields?.[lstfieldsName[index]]?.setValidation("required", true, false);
          } else {
            interviewfields?.[lstfieldsName[index]]?.setValidation("required", false, false);
            interviewfields?.[lstfieldsName[index]]?.setError("");
          }
        }
        for (let index = 0; index < lstNotfieldsName.length; index++) {
          if (!blnValue) {
            interviewfields?.[lstNotfieldsName[index]]?.setValidation("required", true, false);
          } else {
            interviewfields?.[lstNotfieldsName[index]]?.setValidation("required", false, false);
            interviewfields?.[lstNotfieldsName[index]]?.setError("");
          }
        }

        appState.userInfo = handler?.getUserObj({ fields, interviewfields, state });
        appDispatch();
      },

    handleSubmit: async ({ fields, interviewfields, navigate, state }) => {
      try {
        if (!fields?.controller?.isFormValid() || !interviewfields?.controller?.isFormValid()) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.invalid_Field?.[lang], "error", appLangDirection?.[lang], alertStyle);
          return;
        }
        if (isLoading) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "warning", appLangDirection?.[lang], alertStyle);
          return;
        }
        setIsLoading(true);
        const objectInput = handler?.getUserObj({ fields, interviewfields, state });
        if (!appState?.clientInfo?.blnIsUserLoggedIn) {
          objectInput.strUserPassword = fields?.strUserPassword?.getValue();
          objectInput.lstStatisticFelid = ["intCountJoinUser", "intCountJoinUserAll", "intCountJoinStudentAll", "intCountJoinStudent"];
          objectInput.blnIsStudent = true;
          objectInput.jsnUserInfo.strFullName= { eng: fields?.strFullName?.getValue() || "", arb: fields?.strFullName?.getValue() || "" };

          const result = await tblUser(strPageInfo, appState, "aaiotItcUserAdd", {
            objectInput: objectInput,
          });
          if (!result?.blnIsRequestSuccessful) {
            appFunctions.logMessage(result?.response, "");
            if (dictionary?.shared?.alertFetchNote?.[result?.response]) {
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.[result?.response]?.[lang], "warning", appLangDirection?.[lang], alertStyle);
            } else {
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Save_Record?.[lang], "warning", appLangDirection?.[lang], alertStyle);
            }
            setIsLoading(false);
            return;
          }
          appState.userInfo = result.response;
          appState.clientInfo.dtmLastLoggingIn = new Date().toUTCString();
        } else {
          const result = await tblUser(strPageInfo, appState, "aaiotAppUserUpdate", {
            objectInput: { ...objectInput, dtmUpdatedDate: new Date()?.toUTCString() },
            objectCondition: {
              bigAppID: appVariables.App_ID,
              bigSystemID: appState?.userInfo?.bigSystemID,
              bigUserID: appState?.userInfo?.bigUserID,
            },
          });
          if (!result?.blnIsRequestSuccessful) {
            appFunctions.logMessage(result?.response, "");
            if (dictionary?.shared?.alertFetchNote?.[result?.response]) {
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.[result?.response]?.[lang], "warning", appLangDirection?.[lang], alertStyle);
            } else {
              Alert.viewAlert(dictionary?.shared?.alertFetchNote?.cant_Save_Record?.[lang], "warning", appLangDirection?.[lang], alertStyle);
            }
            setIsLoading(false);
            return;
          }
        }

        setIsLoading(false);
        if (!appState.clientInfo.blnIsUserLoggedIn) {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.submit_Successfully?.[lang], "success", appLangDirection?.[lang], alertStyle);
        } else {
          Alert.viewAlert(dictionary?.shared?.alertFetchNote?.updated_Successfully2?.[lang], "success", appLangDirection?.[lang], alertStyle);
        }

        appState.clientInfo.blnIsUserLoggedIn = true;
        appDispatch();
        fields?.controller?.resetForm();
        navigate(appRouting?.Account?.url, { replace: true });
      } catch (error) {
        appFunctions.logMessage(strPageInfo, "handleSubmit-ERROR", error);
        setIsLoading(false);
      }
    },
  };

  return handler;
};
