import React, { useEffect, useId, useRef, useState } from "react";
import { Avatar, Grid, Container, Typography, Badge, Modal, ModalBody, Button, Loader } from "@cbmisorg/material";
import { modalOverFllow } from "../../../util/appFunctions";
import eCertificateCtrl, { objCertificateType, objUploadNameType } from "./eCertificate.controller";
import { useApp } from "../../../configuration/contextapi/context";
import { objStyle } from "./eCertificate.style";
import { ClassesBuilder } from "@cbmisorg/styles";
import { dictionary } from "../../../util/appLanguage/dictionary";
import { App_Dark_Color, App_info_Color, App_Primary_Color, appStyle } from "../../../util/appStyle";
import { FormData, FormField } from "../../../util/formApi/FormAPI";
import excelIcon from "../../../../old/assets/images/excel-icon.png";
import { Icon } from "@cbmisorg/icons";
import ShredHeader from "../../shared/shredHeader";
import "./assets/font.css";

export default function ECertificateView() {
  const { appState } = useApp();
  const lang = appState?.clientInfo?.strLanguage;
  const classes = ClassesBuilder(objStyle, { lang });
  const labels = dictionary?.components?.public?.ecertificate;
  const windowWodthD = window?.innerWidth > 600;

  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    mouseX: null,
    mouseY: null,
    startX: null,
    startY: null,
    ctxWidth: null,
    ctxHeight: null,
    isDown: false,
    lstFloatName: [],
    fileCertificate: null,
    blnOpenSelectModal: false,
  });

  const scrollRef = useRef({ scrollTop: 0, scrollLeft: 0 });
  const canvasRef = useRef(null);
  const imageRef = useRef(null);
  const canvasID = "canvas-" + useId().replaceAll(":", "");
  const imageID = "imageplot-" + useId().replaceAll(":", "");
  const inputUploadID = "uploadimagetemplate-" + useId().replaceAll(":", "");

  const fields = FormData({
    strInputNameType: {
      control: "list",
      label: { eng: "Method of Uploading Names", arb: "أسلوب تحميل الأسماء" },
      validations: { required: true },
      options: [
        { key: objUploadNameType.uploadFromExcel, value: { eng: "Uploading names from an Excel file", arb: "تحميل الأسماء من ملف إكسل" } },
        { key: objUploadNameType.inputManual, value: { eng: "Manually uploading names", arb: "تحميل الأسماء بشكل يدوي" } },
        { key: objUploadNameType.api, value: { eng: "From API request", arb: "تحميل الأسماء من API" } },
      ],
    },
    strApiLink: {
      control: "text",
      label: { eng: "URL", arb: "الرابط" },
      blnLanguage: false,
    },
    strApiFieldName: {
      control: "text",
      label: { eng: "The name fields", arb: "الحقول الخاصة بالأسماء" },
      blnLanguage: false,
    },
    strInputName: {
      control: "textarea",
      label: { eng: "Entering names", arb: "إدخال الأسماء" },
      blnLanguage: false,
      validations: { required: true },
    },
    strCertificateType: {
      control: "list",
      label: { eng: "Specifying the type of certificates", arb: "تحديد نوع الشهادات" },
      validations: { required: true },
      options: [
        { key: objCertificateType.paper_e_cert, value: { eng: "Electronic certificates and paper certificates", arb: "شهادات الكترونية و ورقية" } },
        { key: objCertificateType.e_cert, value: { eng: "Electronic certificates", arb: "شهادات الكترونية" } },
        { key: objCertificateType.paper_cert, value: { eng: "Paper certificates", arb: "شهادات ورقية" } },
      ],
      value: { key: objCertificateType.paper_e_cert, value: { eng: "Electronic certificates and paper certificates", arb: "شهادات الكترونية و ورقية" } },
    },
    strPreName: {
      control: "text",
      label: { eng: "Title before the name", arb: "لقب قبل الاسم" },
      blnLanguage: false
    },
  });

  const handlers = eCertificateCtrl({ appState });
  const handleMouseDown = handlers.handleMouseDown({ canvasRef, scrollRef, state, fields });
  const handleMouseUp = handlers.handleMouseUp({ state });
  const handleMouseOut = handlers.handleMouseOut({ state });
  const handleMouseMove = handlers.handleMouseMove({ canvasRef, scrollRef, setState, state, imageID, fields });
  const handleScrollRegion = handlers.handleScrollRegion({ scrollRef });
  const setCertificateImage = handlers.setCertificateImage({ state, setState });
  const previewPhoto = handlers.previewPhoto({ imageRef, canvasID, canvasRef, imageID, scrollRef, setState, state });
  const uploadNameSheet = handlers.uploadNameSheet({ state, setState, fields });
  const uploadNameOnKeyEnter = handlers.uploadNameOnKeyEnter({ state, setState, fields });
  const downloadImg = handlers.downloadImg({ imageID, state, setState, scrollRef, fields, canvasRef, imageRef, isLoading, setIsLoading });

  useEffect(() => {
    let viewport = document.querySelector('meta[name="viewport"]');
    return () => {
      if (viewport && viewport.content !== "width=device-width, initial-scale=1") {
        viewport.content = "width=device-width, initial-scale=1";
      }

      modalOverFllow();
    };
  }, []);

  return (
    <>
      <ShredHeader lang={lang} title={labels?.title?.[lang]} blnBack={false} blnIsLight={windowWodthD} />
      <Loader backdrop={isLoading} color={App_info_Color} />

      <section className={classes?.secDark}>
        <Container className={classes?.mainContainer} mt-5 p-0>
          <Grid container justifyContent={"center"} alignSelf="center" p-0>
            <Grid item xs="12" md="9" lg="7" xl="6" className={classes?.bgBox}>
              <Container className={classes?.container} py-5>
                {!isLoading ? null : <Loader backdrop={true} color="secondary" />}
                {/* section 1 - upload names */}
                <Grid container>
                  <Typography
                    as="subtitle1"
                    className={`${classes?.subtitleLabel}`}
                    sx={{
                      position: "absolute !important",
                      top: "42% !important",
                      right: "5px !important",
                      left: "1px !important",
                      color: App_Primary_Color,
                      sm: { color: App_info_Color },
                    }}
                  >
                    1
                  </Typography>

                  <Grid item alignItems="center" className={classes?.subtitle} p-3>
                    <Typography as="subtitle1" className={classes?.subtitleLabel}>
                      {labels?.label?.enterNames?.[lang]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={12} my-2>
                    <FormField objHandler={fields.strInputNameType} icon="fileUpload" mode={"classic"} className={classes?.field} />
                  </Grid>

                  <Grid item xs={12}>
                    {fields?.strInputNameType?.getValue()?.key === objUploadNameType?.uploadFromExcel ? (
                      <Grid container justifyContent="center">
                        <Grid item alignItems="center">
                          <input hidden id="upload-names-excel" type="file" accept={".xlsx, .xls, .csv"} onChange={uploadNameSheet} />
                          <label htmlFor="upload-names-excel" style={{ cursor: "pointer", borderRadius: "4px", background: "#445c99" }}>
                            <Grid container>
                              <Grid item p-0>
                                {state?.lstFloatName?.length ? (
                                  <Badge content={state?.lstFloatName?.length || ""} color={App_Dark_Color} className={classes?.badgeStep}>
                                    <img src={excelIcon} alt="" width={"50px"} style={{ borderRadius: "4px", filter: `hue-rotate(90deg)` }} />
                                  </Badge>
                                ) : (
                                  <img src={excelIcon} alt="" width={"50px"} style={{ borderRadius: "4px", filter: `hue-rotate(90deg)` }} />
                                )}
                              </Grid>
                              <Grid item p-0>
                                <Icon icon="fileUpload" sx={{ color: "#fff", fontSize: "20px" }} p-0 />
                              </Grid>
                            </Grid>
                          </label>
                        </Grid>
                      </Grid>
                    ) : fields?.strInputNameType?.getValue()?.key === objUploadNameType?.inputManual ? (
                      <Grid container my-2 justifyContent="space-between">
                        <Grid item xs="10" md={11} alignItems="center">
                          <FormField rows={"3"} objHandler={fields.strInputName} className={classes?.field} onBlur={uploadNameSheet} onKeyDown={uploadNameOnKeyEnter} />
                          <Typography as="caption">{{ arb: "يتم كتابة كل اسم في سطر منفصل", eng: "Each name is written on a separate line" }?.[lang]}</Typography>
                        </Grid>
                        <Grid item alignItems="center">
                          <Avatar className={classes?.avatarStep}>
                            <Typography as="subtitle1">{state?.lstFloatName?.length || "0"}</Typography>
                          </Avatar>
                        </Grid>
                      </Grid>
                    ) : fields?.strInputNameType?.getValue()?.key === objUploadNameType?.api ? (
                      <Grid container my-2>
                        <Grid item xs={12} alignItems="center" my-2>
                          <FormField inputDir="ltr" objHandler={fields.strApiLink} icon="link" mode={"classic"} className={classes?.field} />
                        </Grid>
                        <Grid item xs={12} alignItems="center" my-2>
                          <FormField inputDir="ltr" objHandler={fields.strApiFieldName} icon="formatQuote" mode={"classic"} className={classes?.field} />
                          <Typography as="caption">{{ eng: `Separated by commas (,)`, arb: `تفصل بين الحقول باستخدام الفاصلة (,)` }?.[lang]}</Typography>
                        </Grid>
                        <Grid item xs={12} alignItems="center" my-2>
                          <Grid container justifyContent="center">
                            <Grid item>
                              <Button size="sm" className={classes?.btnbox} mode="outlined" label={labels?.label?.fetchAPI?.[lang]} onClick={uploadNameSheet} />
                            </Grid>
                            <Grid item alignItems="center">
                              <Avatar className={classes?.avatarStep}>
                                <Typography as="subtitle1">{state?.lstFloatName?.length || "0"}</Typography>
                              </Avatar>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : null}
                  </Grid>

                  {!state?.lstFloatName?.length  ? null : (
                    <Grid item xs={12} my-2>
                      <FormField objHandler={fields.strPreName} icon="badge" mode={"classic"} className={classes?.field} />
                    </Grid>
                  )}
                </Grid>

                {/* <img src={lineImage} alt="" className={classes?.imgLine} style={{ margin: "25px auto 20px" }} /> */}

                {/* section 2 - upload certificate */}

                <Grid container>
                  {!state?.lstFloatName?.length ? null : (
                    <Typography
                      as="subtitle1"
                      className={classes?.subtitleLabel}
                      sx={{
                        position: "absolute !important",
                        top: "42% !important",
                        right: "5px !important",
                        left: "1px !important",
                        color: App_Primary_Color,
                        sm: { color: App_info_Color },
                      }}
                    >
                      2
                    </Typography>
                  )}

                  <Grid item alignItems="center" className={state?.lstFloatName?.length ? classes?.subtitle : classes?.subtitleGray} p-3>
                    <Typography
                      as="subtitle1"
                      className={classes?.subtitleLabel}
                      sx={{
                        padding: "5px 0px !important",
                        right: "55px !important",
                        left: "55px !important",
                      }}
                    >
                      {labels?.label?.enterCertImg?.[lang]}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container justifyContent="center" hidden={!state?.lstFloatName?.length}>
                  <Grid item alignItems="center">
                    <input hidden type="file" accept={".jpg, .jpeg, .png, .svg"} id={inputUploadID} onChange={setCertificateImage} />
                    <label htmlFor={inputUploadID} style={{ cursor: "pointer" }}>
                      <Grid container sx={{ borderRadius: "4px", background: "#445c99" }}>
                        <Grid item p-0>
                          <Icon icon={"image"} sx={{ fontSize: "50px", color: "#ffffff" }} />
                        </Grid>
                        <Grid item p-0>
                          <Icon icon="fileUpload" sx={{ color: "#fff", fontSize: "20px" }} p-0 />
                        </Grid>
                      </Grid>
                    </label>
                  </Grid>
                </Grid>

                {/* <img src={lineImage} alt="" className={classes?.imgLine} style={{ margin: "25px auto 20px" }} /> */}

                {/* section 3 - select area */}

                <Grid container>
                  {!state?.fileCertificate ? null : (
                    <Typography
                      as="subtitle1"
                      className={classes?.subtitleLabel}
                      sx={{
                        position: "absolute !important",
                        top: "42% !important",
                        right: "5px !important",
                        left: "1px !important",
                        color: App_Primary_Color,
                        sm: { color: App_info_Color },
                      }}
                    >
                      3
                    </Typography>
                  )}

                  <Grid item alignItems="center" className={state?.fileCertificate ? classes?.subtitle : classes?.subtitleGray} p-3>
                    <Typography
                      as="subtitle1"
                      className={classes?.subtitleLabel}
                      sx={{
                        padding: "5px 24px !important",
                      }}
                    >
                      {labels?.label?.selectNameArea?.[lang]}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container justifyContent="center" hidden={!state?.fileCertificate}>
                  <Grid item alignItems="center">
                    <Button py-0 size="sm" label={labels?.label?.selectNameAreaBtn?.[lang]} onClick={previewPhoto} mode="outlined" className={classes?.btnbox} />
                  </Grid>
                </Grid>

                {/* <img src={lineImage} alt="" className={classes?.imgLine} style={{ margin: "25px auto 20px" }} /> */}

                {/* section 4 - certificate type */}

                <Grid container>
                  {state.startX === null ? null : (
                    <Typography
                      as="subtitle1"
                      className={classes?.subtitleLabel}
                      sx={{
                        position: "absolute !important",
                        top: "42% !important",
                        right: "5px !important",
                        left: "1px !important",
                        color: App_Primary_Color,
                        sm: { color: App_info_Color },
                      }}
                    >
                      4
                    </Typography>
                  )}

                  <Grid item alignItems="center" className={state.startX ? classes?.subtitle : classes?.subtitleGray} p-3>
                    <Typography
                      as="subtitle1"
                      className={classes?.subtitleLabel}
                      sx={{
                        padding: "5px 36px !important",
                      }}
                    >
                      {labels?.label?.certificateType?.[lang]}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container hidden={state.startX === null}>
                  <Grid item xs={12} my-2>
                    <FormField objHandler={fields.strCertificateType} icon="documentScannerOutlined" mode={"classic"} className={classes?.field} />
                  </Grid>
                </Grid>

                {/* <img src={lineImage} alt="" className={classes?.imgLine} style={{ margin: "25px auto 20px" }} /> */}

                <Grid container hidden={state.startX === null}>
                  <Grid xs={12} py-8 item alignItems="center">
                    <Button
                      label={labels?.label?.prepareCertificate?.[lang]}
                      onClick={downloadImg}
                      mode="outlined"
                      color={windowWodthD ? App_Primary_Color : App_info_Color}
                      className={classes?.btn}
                    />
                  </Grid>
                </Grid>
              </Container>
            </Grid>
          </Grid>
        </Container>
      </section>
      <div style={{ fontFamily: "'MonteCarlo', cursive", opacity: 0 }}>Generate Certificates</div>

      <Modal
        open={state.blnOpenSelectModal}
        /*    eventClose={() => {
          modalOverFllow();
          setState({ ...state, blnOpenSelectModal: false });
        }} */
        className={classes?.modal}
        sx={{ width: "100% !important" }}
        p-0
      >
        <ModalBody p-0>
          <Grid container justifyContent="space-between">
            <Grid item alignItems="center">
              <Button
                label={labels?.label?.doneBtn?.[lang]}
                disabled={state.startX === null}
                onClick={() => {
                  let viewport = document.querySelector('meta[name="viewport"]');
                  if (viewport && viewport?.content !== "width=device-width, initial-scale=1") {
                    viewport.content = "width=device-width, initial-scale=1";
                  }
                  modalOverFllow();
                  setState({ ...state, blnOpenSelectModal: false });
                }}
                mode="outlined"
                sx={{
                  fontSize: "large",
                  borderWidth: "2px",
                  span: {
                    ...appStyle?.fontsMada,
                  },
                  "&hover": {
                    background: appStyle.App_Second_Opacity_Color,
                    color: appStyle.App_Light_Text_Color,
                  },
                  sm: {
                    fontSize: "medium",
                  },
                }}
                p-0
              />
            </Grid>
            <Grid item alignItems="center" justifyContent="center">
              <Typography as="subtitle2" color={appStyle.App_info_Color} className={classes?.labelModal}>
                {labels?.label?.selectModalTitle?.[lang]}
              </Typography>
            </Grid>
          </Grid>

          <Grid container justifyContent="center">
            <Grid item justifyContent="flex-start" p-0 sx={{ overflow: "auto", maxWidth: "100vw", maxHeight: "82vh" }} onScroll={handleScrollRegion}>
              <img alt="" id={imageID} style={{ zIndex: 5, width: "11.69in", height: "8.27in" }} />
              <canvas
                id={canvasID}
                style={{ border: "1px solid red", position: "absolute", zIndex: 10, overflow: "auto" }}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseOut={handleMouseOut}
                onTouchStart={handleMouseDown}
                onTouchMove={handleMouseMove}
                // onTouchEnd={handleMouseUp}
                onTouchCancel={handleMouseUp}
              ></canvas>
            </Grid>
          </Grid>
        </ModalBody>
      </Modal>
    </>
  );
}
