import React, { useEffect, useState } from "react";
import ShredHeader from "../../../sharedUi/header/ShredHeader";
import { dictionary } from "../../../../appHelper/appLanguage/dictionary";
import * as appVariables from "../../../../appHelper/appVariables";

import team1 from "../../../../assets/images/tea1.jpg";
import team2 from "../../../../assets/images/team2.jpg";
import team3 from "../../../../assets/images/team3.jpg";
import team4 from "../../../../assets/images/team4.jpg";
import team5 from "../../../../assets/images/team5.jpg";
import team6 from "../../../../assets/images/team6.jpg";
import team7 from "../../../../assets/images/team7.jpg";
import team8 from "../../../../assets/images/team8.jpg";
import team9 from "../../../../assets/images/team9.jpg";
import team10 from "../../../../assets/images/team10.jpg";
import team11 from "../../../../assets/images/team11.jpg";
import team12 from "../../../../assets/images/team12.jpg";
import team13 from "../../../../assets/images/team13.jpg";
import team14 from "../../../../assets/images/team14.jpg";
import team15 from "../../../../assets/images/team15.jpg";

import { ClientDirection, Container, Grid } from "@cbmisorg/client-app";
import MainMemberCard from "../../../sharedUi/memberCard/MainMemberCard";
import { useApp } from "../../../../../client/configuration/contextapi/context";
// import NoResultImg from '../../../sharedUi/noResultImg/NoResultImg';

const objTeam = {
  [appVariables.memberTypeID.founder]: [
    {
      bigID: 1,
      image: team1,
      strName: { eng: "Sheikh Mohammed AlSabah", arb: "الشيخ محمد أحمد الصباح" },
      strPosition: { eng: "Chairman of Board of Directors", arb: "المؤسس ورئيس مجلس الإدارة" },
      intWorkTyp: 0,
      strWorkplace: "",
      blnIsMainFounder: false,
      bigMemberTypeID: appVariables.memberTypeID.founder,
      blnDirector: true,
      strQto: {
        eng: "We seek to strengthen Kuwait’s position by developing an innovative technical structure, which aims to develop competencies, help companies and members of society, attract sustainable investments and effectively contribute to research and innovation in various technical fields.",
        arb: "نسعى إلى تعزيز مكانة الكويت من خلال تطوير بنية تقنية مبتكرة، تهدف إلى تطوير الكفاءات ومساعدة الشركات وأفراد المجتمع، واستقطاب الاستثمارات المستدامة والمساهمة الفاعلة في البحث والابتكار في مختلف المجالات التقنية.",
      },
      dtmDate: "01/01/2022",
      intDeg: appVariables.degreeID.bachelor,
      blnAct: true,
      strLinkedin: "https://www.linkedin.com/in/mohammed-al-sabah-mba-11898162/",
    },
    {
      bigID: 2,
      image: team2,
      strName: { eng: "Dr. Ammar AlHusseini", arb: "د. عمار الحسيني" },
      strPosition: { eng: "", arb: "" },
      intWorkTyp: appVariables.memberWorkTypeID.professor,
      strWorkplace: { eng: "", arb: "" },
      bigMemberTypeID: appVariables.memberTypeID.founder,
      dtmDate: "01/01/2024",
      intDeg: appVariables.degreeID.bachelor,
      blnAct: true,
    },
    {
      bigID: 3,
      image: team3,
      strName: { eng: "Saqr Mufarreh AlHarbi", arb: "صقر مفرح الحربي" },
      strPosition: { eng: "Vice President", arb: "نائب الرئيس" },
      intWorkTyp: appVariables.memberWorkTypeID.professor,
      strWorkplace: { eng: "", arb: "" },
      bigMemberTypeID: appVariables.memberTypeID.founder,
      blnDirector: true,
      dtmDate: "01/01/2024",
      intDeg: appVariables.degreeID.bachelor,
      blnAct: true,
      strAbout: {
        arb: "أعمل في الإدارة التنفيذية ولدي القدرة على بناء الاستراتيجيات والعمل على تنفيذها من خلال إدارة الموظفين في مختلف الظروف والإدارة الاقتصادية مستفيدا من مهاراتي في التحليل والمنطق",
        eng: "Working in executive management and have the ability to build strategies and work to implement them through managing staff in various circumstances and economic management benefiting from my skills in analysis and logic",
      },
      strLinkedin: "https://www.linkedin.com/in/saqeralharbi/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    },
    {
      bigID: 4,
      image: team4,
      strName: { eng: "Jamal AlHamoud", arb: "جمال الحمود" },
      strPosition: { eng: "", arb: "" },
      intWorkTyp: appVariables.memberWorkTypeID.professor,
      strWorkplace: { eng: "", arb: "" },
      bigMemberTypeID: appVariables.memberTypeID.founder,
      dtmDate: "01/01/2024",
      intDeg: appVariables.degreeID.bachelor,
      blnAct: true,
    },
    {
      bigID: 5,
      image: team5,
      strName: { eng: "Eng. Khaled Al Hatem", arb: "م. خالد الحاتم" },
      strPosition: { eng: "", arb: "" },
      intWorkTyp: appVariables.memberWorkTypeID.professor,
      strWorkplace: { eng: "", arb: "" },
      bigMemberTypeID: appVariables.memberTypeID.founder,
      dtmDate: "01/01/2024",
      intDeg: appVariables.degreeID.bachelor,
      blnAct: true,
    },
    {
      bigID: 6,
      image: team6,
      strName: { eng: "Eng. Fares AlEnezi", arb: "م. فارس العنزي" },
      strPosition: { eng: "", arb: "" },
      intWorkTyp: appVariables.memberWorkTypeID.professor,
      strWorkplace: { eng: "", arb: "" },
      bigMemberTypeID: appVariables.memberTypeID.founder,
      dtmDate: "01/01/2024",
      intDeg: appVariables.degreeID.bachelor,
      blnAct: true,
    },
    {
      bigID: 7,
      image: team7,
      strName: { eng: "Dr. Badr AlDhaidi", arb: "د. بدر الذايدي" },
      strPosition: { eng: "", arb: "" },
      intWorkTyp: appVariables.memberWorkTypeID.professor,
      strWorkplace: { eng: "", arb: "" },
      bigMemberTypeID: appVariables.memberTypeID.founder,
      dtmDate: "01/01/2024",
      intDeg: appVariables.degreeID.bachelor,
      blnAct: true,
    },
    {
      bigID: 8,
      image: team8,
      strName: { eng: "Dr. Naji AlDhafiri", arb: "د. ناجي الظفيري" },
      strPosition: { eng: "", arb: "" },
      intWorkTyp: appVariables.memberWorkTypeID.professor,
      strWorkplace: { eng: "", arb: "" },
      bigMemberTypeID: appVariables.memberTypeID.founder,
      dtmDate: "01/01/2024",
      intDeg: appVariables.degreeID.bachelor,
      blnAct: true,
    },
    {
      bigID: 9,
      image: team9,
      strName: { eng: "Dr. Ayed Atallah", arb: "د. عايد عطا الله" },
      strPosition: { eng: "Committee Undersecretary", arb: "أمين السر" },
      intWorkTyp: appVariables.memberWorkTypeID.professor,
      strWorkplace: { eng: "", arb: "" },
      bigMemberTypeID: appVariables.memberTypeID.founder,
      blnDirector: true,
      dtmDate: "01/01/2024",
      intDeg: appVariables.degreeID.bachelor,
      blnAct: true,
      strAbout: {
        eng: "Experienced Professor with a demonstrated history of working in the higher education industry. Strong education professional skilled in Artificial Intelligence, Computational Intelligence, Data Science, Machine Learning, Value Eng.ing, Analytical Skills, Requirements Analysis, Business Process Design, and Computer Science.",
        arb: "أستاذ ذو خبرة وله تاريخ حافل من العمل في قطاع التعليم العالي. تعليم مهني قوي ماهر في الذكاء الاصطناعي، والذكاء الحسابي، وعلوم البيانات، والتعلم الآلي، وهندسة القيمة، والمهارات التحليلية، وتحليل المتطلبات، وتصميم العمليات التجارية، وعلوم الكمبيوتر.",
      },
      strLinkedin: "https://www.linkedin.com/in/prof-ayed-salman-5a434213/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    },
    {
      bigID: 10,
      image: team10,
      strName: { eng: "Eng. Youssef AlMutairi", arb: "م. يوسف المطيري" },
      strPosition: { eng: "Secretary Treasurer", arb: "أمين الصندوق" },
      intWorkTyp: appVariables.memberWorkTypeID.professor,
      strWorkplace: { eng: "", arb: "" },
      bigMemberTypeID: appVariables.memberTypeID.founder,
      blnDirector: true,
      dtmDate: "01/01/2024",
      intDeg: appVariables.degreeID.bachelor,
      blnAct: true,
      strAbout: { eng: "", arb: "" },
      strLinkedin: "https://www.linkedin.com/in/yousef-almutairi-8bb02960/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    },
    {
      bigID: 11,
      image: team11,
      strName: { eng: "Eng. Ghanem AlOtaibi", arb: "م. غانم العتيبي" },
      strPosition: { eng: "", arb: "" },
      intWorkTyp: appVariables.memberWorkTypeID.professor,
      strWorkplace: { eng: "", arb: "" },
      bigMemberTypeID: appVariables.memberTypeID.founder,
      dtmDate: "01/01/2024",
      intDeg: appVariables.degreeID.bachelor,
      blnAct: true,
    },
    {
      bigID: 12,
      image: team12,
      strName: { eng: "Eng. Ghazi AlHarbi", arb: "م. غازي الحربي" },
      strPosition: { eng: "", arb: "" },
      intWorkTyp: appVariables.memberWorkTypeID.professor,
      strWorkplace: { eng: "", arb: "" },
      bigMemberTypeID: appVariables.memberTypeID.founder,
      dtmDate: "01/01/2024",
      intDeg: appVariables.degreeID.bachelor,
      blnAct: true,
    },
    {
      bigID: 13,
      image: team13,
      strName: { eng: "Eng. Sultan AlShammari", arb: "م. سلطان الشمري" },
      strPosition: { eng: "", arb: "" },
      intWorkTyp: appVariables.memberWorkTypeID.professor,
      strWorkplace: { eng: "", arb: "" },
      bigMemberTypeID: appVariables.memberTypeID.founder,
      dtmDate: "01/01/2024",
      intDeg: appVariables.degreeID.bachelor,
      blnAct: true,
    },
    {
      bigID: 14,
      image: team14,
      strName: { eng: "Mohammed AlAjmi", arb: "محمد العجمي" },
      strPosition: { eng: "", arb: "" },
      intWorkTyp: appVariables.memberWorkTypeID.professor,
      strWorkplace: { eng: "", arb: "" },
      bigMemberTypeID: appVariables.memberTypeID.founder,
      dtmDate: "01/01/2024",
      intDeg: appVariables.degreeID.bachelor,
      blnAct: true,
    },
    {
      bigID: 15,
      image: team15,
      strName: { eng: "Dr. Ali AlFaili", arb: "د. على الفيلي" },
      strPosition: { eng: "Member", arb: "عضو" },
      intWorkTyp: appVariables.memberWorkTypeID.professor,
      strWorkplace: { eng: "", arb: "" },
      bigMemberTypeID: appVariables.memberTypeID.founder,
      blnDirector: true,
      dtmDate: "01/01/2024",
      intDeg: appVariables.degreeID.bachelor,
      blnAct: true,
      strAbout: {
        eng: "Assistant professor at the computer department in CBE and Chair at IEEE Young Professionals Kuwait",
        arb: "أستاذ مساعد في قسم الكمبيوتر في CBE ورئيس معهد IEEE Young Professionals الكويت",
      },
      strLinkedin: "https://www.linkedin.com/in/dr-ali-alfayly/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    },
  ],
  [appVariables.memberTypeID.worker]: [],
  [appVariables.memberTypeID.associate]: [],
  [appVariables.memberTypeID.honorary]: [],
};

const GetTabContentMembers = ({ stateInfo, lang = "lang" }) => {
  const director = [];
  let jxsMember = "";
  stateInfo.currentList.forEach((item, index) => {
    if (item?.bigMemberTypeID === appVariables.memberTypeID.founder && item?.blnDirector) {
      director.push(
        <Grid item xs="12" md="4" container key={index} p-5 py-2>
          <MainMemberCard memberInfo={item} lang={lang} />
        </Grid>
      );
    }
  });

  return (
    <Grid container spacing="3" pb-xs-10 pb-sm-10 pb-10 justify={"center"}>
      {jxsMember}
      {director}
      {
        // content?.length?content:
        // <NoResultImg />
      }
    </Grid>
  );
};
function Members() {
  const { appState } = useApp();
  const lang = appState?.clientInfo?.strLanguage || "arb";
  const [stateInfo] = useState({
    currentList: objTeam[appVariables.memberTypeID.founder],
    bigMemberTypeIDSelected: appVariables.memberTypeID.founder,
    intWorkTypSelected: 1,
    openMenu: false,
  });

  useEffect(() => {
    ClientDirection.setDirection(lang === "arb" ? "rtl" : "ltr");
  }, [lang]);

  return (
    <React.Fragment>
      <ShredHeader title={dictionary?.components?.public?.landingPage?.members?.directors?.[lang]} lang={lang} />
      <Container pb-xs-10 pb-sm-10 mb-xs-10 mb-sm-10>
        <Grid container justify={2} spacing={2}>
        <GetTabContentMembers stateInfo={stateInfo} lang={lang} />
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default Members;
