import React, { useEffect, useState } from "react";
import { dictionary } from "../../../../appHelper/appLanguage/dictionary";
import { Badge, Container, Grid, Icon, Typography, useNavigate, Box, Alert } from "@cbmisorg/client-app";
import objAppRouting from "../../../../appHelper/routing/appRouting";
import { checkParseObject, getNextStrURL, logMessage } from "../../../../appHelper/appFunctions";
import useCheckSystemInfo from "../../../../appHelper/appHook/useCheckSystemInfo";
import useCheckCat from "../../../../appHelper/appHook/useCheckCat";
import { generateQueries } from "../../../../appHelper/appQuery/GenerateQuery";
import { generatePath } from "react-router-dom";
import { objUserRole } from "../../../../appHelper/appVariables";
import { useApp } from "../../../../../client/configuration/contextapi/context";
import { decompressText, getAppLocalStorage } from "../../../../../client/util/appFunctions";
import appRouting from "../../../../../client/configuration/routing/appRouting";
import useCheckHooksGlb from "../../../../../client/util/hooks/useCheckHooksGlb";

const strPageInfo = "@/src/components/stakeholders/admin/account/Account.js";
const tblStatistic = generateQueries("tblStatistic");

function AdminAccount() {
  const navigate = useNavigate();
  const { appState, appDispatch } = useApp();
  // useCheckUserLoginDevice()
  useCheckHooksGlb()
  const { getNewSystemInfo } = useCheckSystemInfo({ notHook: true, isGetCount: true });
  const { getNewCatInfo } = useCheckCat(true);
  const lang = appState?.clientInfo?.strLanguage;
  const lstUserFeature = appState?.userInfo?.lstUserFeature || [];
  const [isLoading, setIsLoading] = useState(false);

  const objCat = decompressText(getAppLocalStorage()?.clientInfo?.objCatInfo);

  let defTrainingNav = [];
  let defActivtyNav = [];
  Object?.entries(objCat?.Root?.toSupport || {})?.forEach(([key, value]) => {
    if (key !== "id" && key !== "value") {
      if (value?.blnIsTraining?.value?.eng !== "false") {
        defTrainingNav.push({
          icon: "laptop",
          label: value?.value,
          url: generatePath(objAppRouting?.Manage_Training?.url, { strTrainingType: key }),
          featureID: objAppRouting?.Manage_Training?.featureID,
          badge: checkParseObject(value?.badge?.value?.eng),
        });
      } else {
        defActivtyNav.push({
          icon: "AccountBalance",
          label: value?.value,
          url: generatePath(objAppRouting?.Manage_Training?.url, { strTrainingType: key }),
          featureID: objAppRouting?.Manage_Training?.featureID,
          badge: checkParseObject(value?.badge?.value?.eng),
        });
      }
    }
  });

  const mainNav = [
    {
      icon: "group",
      label: dictionary?.components?.managePartner?.title,
      url: generatePath(objAppRouting?.Manage_Partner?.url),
      featureID: objAppRouting?.Manage_Partner?.featureID,
      badge: "intCountJoinSponsor",
    },
    // {
    //   icon: "business",
    //   label: dictionary?.components?.mngTraingNav?.startup,
    //   url: generatePath(objAppRouting?.manage_Taining_info?.url, { strTrainingType: "startup" }),
    //   featureID: objAppRouting?.manage_Taining_info?.featureID,
    //   badge: "intCountJoinEntrepreneur",
    // },
    {
      icon: "search",
      label: dictionary?.components?.mngTraingNav?.researcher,
      url: generatePath(objAppRouting?.manage_Taining_info?.url, { strTrainingType: "researcher" }),
      featureID: objAppRouting?.manage_Taining_info?.featureID,
      badge: "intCountJoinResearcher",
    },
    {
      icon: "VolunteerActivism",
      label: dictionary?.components?.mngTraingNav?.volunteer,
      url: generatePath(objAppRouting?.manage_Taining_info?.url, { strTrainingType: "volunteer" }),
      featureID: objAppRouting?.manage_Taining_info?.featureID,
      badge: "intCountJoinVolunteer", 
    },
    {
      icon: "EmojiObjects",
      label: dictionary?.components?.mngTraingNav?.mdeas,
      url: generatePath(objAppRouting?.manage_Taining_info?.url, { strTrainingType: "mdeas" }),
      featureID: objAppRouting?.manage_Taining_info?.featureID,
      badge: "intCountJoinMDEAS", 
    },
    {
      icon: "ContactMail",
      label: dictionary?.components?.contact?.mngTitle,
      url: objAppRouting?.Contact?.url,
      featureID: objAppRouting?.Contact?.featureID,
      badge: "intCountMail",
    },
    {
      icon: "Subscriptions",
      label: dictionary?.components?.supscrption?.title,
      url: objAppRouting?.Subscription?.url,
      featureID: objAppRouting?.Subscription?.featureID,
    },

    {
      icon: "FactCheck",
      label: dictionary?.components?.manageActivates?.mngTitle,
      url: objAppRouting?.ManageActivates?.url,
      featureID: objAppRouting?.ManageActivates?.featureID,
    },
    {
      icon: "campaign",
      label: dictionary?.components?.mngAnnouncement?.Announcement?.title,
      url: objAppRouting?.MngAnnouncement?.url,
      featureID: objAppRouting?.MngAnnouncement?.featureID,
      // badge: "intCountJoinEntrepreneur",
    },
    {
      icon: "liveTv",
      label: dictionary?.components?.mngAnnouncement?.session?.title,
      url: objAppRouting?.MngMeeting?.url,
      featureID: objAppRouting?.MngMeeting?.featureID,
      badge: "intCountMeeting",
    },
    {
      icon: "Assistant",
      label: dictionary?.components?.manageStatistic?.title,
      url: objAppRouting?.ManageStatistic?.url,
      featureID: objAppRouting?.ManageStatistic?.featureID,
    },

    { icon: "AccountCircle", label: dictionary?.components?.manageMembers?.profile, url: objAppRouting?.profile?.url, featureID: objAppRouting?.profile?.featureID },
    {
      icon: "category",
      label: dictionary?.components?.manageCategories?.title,
      url: objAppRouting?.ManageCategories?.url,
      featureID: objAppRouting?.ManageCategories?.featureID,
    },
    {
      icon: "VideoLibrary",
      label: dictionary?.components?.advertisingVideoManagement?.title,
      // url: objAppRouting?.AdvertisingVideoManagement?.url,
      // featureID: objAppRouting?.AdvertisingVideoManagement?.featureID,
      url: appRouting?.ManageMedia?.url,
      featureID: appRouting?.ManageMedia?.featureID,
    },
    { icon: "Settings", label: dictionary?.components?.manageSystem?.title, url: objAppRouting?.ManageSystem?.url, featureID: objAppRouting?.ManageSystem?.featureID },

    {
      icon: "CardMembership",
      label: dictionary?.components?.account?.generateCertificate,
      url: appRouting?.Generate_Certificate?.url,
      featureID: appRouting?.Generate_Certificate?.featureID,
    },
    // {
    //   icon: "people",
    //   label: dictionary?.components?.account?.attendeeManagement,
    //   url: objAppRouting?.AttendeeManagement?.url,
    //   featureID: appRouting?.AttendeeManagement?.featureID,
    // },
    {
      icon: "groups",
      label: dictionary?.components?.account?.mngForumReg,
      url: appRouting?.MngRegForum?.url,
      featureID: appRouting?.MngRegForum?.featureID,
      badge: "intNumNeedToConfirmUser",
    },
    {
      icon: "Task",
      label: dictionary?.components?.account?.mngSurvey,
      url: appRouting?.MngSurvey?.url,
      featureID: appRouting?.MngSurvey?.featureID,
    },
  ];

  const initData= async()=>{
    await getNewCatInfo();
    await getNewSystemInfo(appDispatch);
  }

  useEffect(() => {
    const accountUrl = getNextStrURL();
    if (accountUrl !== objAppRouting?.Account?.url) {
      navigate(accountUrl, { replace: true });
    }
    initData()
  }, []);

  const functionClickedNav = (item) => async () => {
    navigate(item.url);

    if (!item?.badge || Array.isArray(item.badge) || appState?.userInfo?.jsnUserInfo?.intUserRoleFK === objUserRole?.RootAdmin) {
      return;
    }
    if (isLoading) {
      Alert.viewAlert(dictionary?.shared?.alertFetchNote.fetchData_InProgress?.[lang], "warning");
      return;
    }

    setIsLoading(true);
    const result = await tblStatistic(strPageInfo, appState, "aaiotAppUpdate", {
      objectInput: {
        [item?.badge]: 0,
        dtmUpdatedDate: new Date().toUTCString(),
      },
      objectCondition: {
        bigSystemID: appState?.clientInfo?.objSystemInfo?.bigSystemID || appState?.userInfo.bigSystemID,
      },
    });
    if (!result?.blnIsRequestSuccessful) {
      logMessage(result?.response, "");
    }
    setIsLoading(false);
  };

  const funGetArrayBadge = (array = []) => {
    let sum = 0;
    for (let index = 0; index < array.length; index++) {
      sum += Number(appState?.clientInfo?.objSystemInfo?.[array[index]]) || 0;
    }
    return sum;
  };

  return (
    <React.Fragment>
      <Container py-10>
        {appState?.userInfo?.jsnUserInfo?.intUserRoleFK === objUserRole.Receptionist ? (
          <Grid container justify={"center"} spacing={1}>
            {[
              {
                icon: "groups",
                label: dictionary?.components?.account?.mngForumReg,
                url: appRouting?.MngRegForum?.url,
                featureID: appRouting?.MngRegForum?.featureID,
                badge: "intNumNeedToConfirmUser",
              },
            ]
              ?.filter((item) => lstUserFeature?.includes(item?.featureID) || !item?.featureID)
              ?.map((item, index) => {
                return (
                  <Grid item xs="6" sm="4" md="3" lg="2" key={index} className="navAccount">
                    <Box className="boxNav" onClick={functionClickedNav(item)}>
                      <Grid container justify={"center"}>
                        <Grid item xs="12">
                          {item?.badge && (appState?.clientInfo?.objSystemInfo?.[item?.badge] || funGetArrayBadge(item?.badge)) ? (
                            <Badge content={!Array.isArray(item?.badge) ? appState?.clientInfo?.objSystemInfo?.[item?.badge] : funGetArrayBadge(item?.badge)}>
                              <Icon icon={item?.icon} color="primary" />
                            </Badge>
                          ) : (
                            <Icon icon={item?.icon} color="primary" />
                          )}
                        </Grid>
                        <Grid item xs="12">
                          <Typography as="caption" className="textcenter">
                            {item?.label?.[lang]}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                );
              })}
          </Grid>
        ) : (
          <Grid container justify={"center"} spacing={1}>
            {[...defTrainingNav, ...(appState?.userInfo?.jsnUserInfo?.intUserRoleFK !== objUserRole?.Marketer ? defActivtyNav : []), ...mainNav]
              ?.filter((item) => lstUserFeature?.includes(item?.featureID) || !item?.featureID)
              ?.map((item, index) => {
                return (
                  <Grid item xs="6" sm="4" md="3" lg="2" key={index} className="navAccount">
                    <Box className="boxNav" onClick={functionClickedNav(item)}>
                      <Grid container justify={"center"}>
                        <Grid item xs="12">
                          {item?.badge && (appState?.clientInfo?.objSystemInfo?.[item?.badge] || funGetArrayBadge(item?.badge)) ? (
                            <Badge content={!Array.isArray(item?.badge) ? appState?.clientInfo?.objSystemInfo?.[item?.badge] : funGetArrayBadge(item?.badge)}>
                              <Icon icon={item?.icon} color="primary" />
                            </Badge>
                          ) : (
                            <Icon icon={item?.icon} color="primary" />
                          )}
                        </Grid>
                        <Grid item xs="12">
                          <Typography as="caption" className="textcenter">
                            {item?.label?.[lang]}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                );
              })}
          </Grid>
        )}
      </Container>
    </React.Fragment>
  );
}

export default AdminAccount;
