import { Alert } from "@cbmisorg/material";
import * as appVariables from "../../../../../../configuration/appVariables";
import * as appFunctions from "../../../../../../util/appFunctions";
import { appLangDirection, dictionary } from "../../../../../../util/appLanguage/dictionary";
import { generateQueries } from "../../../../../../graphql/schema/GenerateQuery";
import SponsorTrainingTypeOptionsView from "./component/sponsorTrainingTypeOptions.view";
import * as appStyle from "../../../../../../util/appStyle";
const partnerApi = generateQueries("tblPartner");
const statisticApi = generateQueries("tblStatistic");

const strPageInfo = "@src/client/component/shared/account/components/sponsorSetterModal/sponsorSetterModal.controller.js";

export default function sponsorSetterModalCtrl({ appState, appDispatch, parentState, setParentState, isLoading, setIsLoading }) {
  const lang = appState?.clientInfo?.strLanguage;
  const objSystemConfig = appState?.clientInfo?.objSystemInfo?.jsnSystemConfig;
  const objCat = appFunctions.decompressText(appFunctions.getAppLocalStorage()?.clientInfo?.objCatInfo);

  const handler = {
    initData:
      ({ state, setState, fields }) =>
      async () => {
        try {
          fields?.controller?.resetForm();

          if (isLoading) {
            Alert.viewAlert(dictionary.shared.alertFetchNote.fetchData_InProgress?.[lang], "warning", appLangDirection?.[lang], appStyle.alertStyle);
            return;
          }

          setIsLoading(true);

          // const objCat = appFunctions.getCategoryCache();
          // let lstToSupportOption = [];
          // let lstSponsorTrainingTypeOption = [];

          // ([...Object.entries(objCat?.Root?.toSupport || {})]).reverse().forEach(([key, value]) => {
          //   if (key !== "id" && key !== "value") {
          //     lstToSupportOption.push({ key: key, value: value?.supportLabel?.value?.[lang] });
          //   }
          // });

          // Object.entries(objCat?.Root?.toSupport?.[lstToSupportOption[0]?.key]?.sponser || {}).forEach(([key, value]) => {
          //   if (key !== "id" && key !== "value") {
          //     const SponsorTrainingTypeOptions = SponsorTrainingTypeOptionsView({
          //       lang,
          //       value,
          //       getPartnerTransPlanTotalPrice: handler?.getPartnerTransPlanTotalPrice(key, objCat),
          //     });
          //     lstSponsorTrainingTypeOption.push({ key: key, value: SponsorTrainingTypeOptions?.optionLabel, jsx: () => SponsorTrainingTypeOptions?.jsx });
          //   }
          // });

          // fields?.strToSupport?.setOptions(lstToSupportOption);
          // fields?.strSponsorTrainingType?.setOptions(lstSponsorTrainingTypeOption);
          // fields?.strToSupport?.setValue(lstToSupportOption[0]);

          if (appState?.userInfo?.jsnUserInfo?.bigWorkSector) {
            fields?.bigWorkSector?.setValue(appState?.userInfo?.jsnUserInfo?.bigWorkSector);
          }
          if (appState?.userInfo?.jsnUserInfo?.strFullName) {
            fields.strSponserName.setValue(
              appState?.userInfo?.jsnUserInfo?.strFullName?.[lang] ||
                appState?.userInfo?.jsnUserInfo?.strFullName?.eng ||
                appState?.userInfo?.jsnUserInfo?.strFullName?.arb ||
                appState?.userInfo?.jsnUserInfo?.strFullName
            );
          }
          if (appState?.userInfo?.strUserPhone) {
            fields.strUserPhone.setValue(appState?.userInfo?.strUserPhone?.slice(5));
          }

          state.blnIsInitDone = true;
          setState({ ...state });
          setParentState({ ...parentState });
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          appFunctions.logError(strPageInfo, "openModal-ERROR", error);
        }
      },
    getPartnerTransPlanTotalPrice: (strSponsorTrainingType, strSponsorType, objCat) => {
      try {
        const intCountStudentNumSystem = Number(objCat?.Root?.toSupport?.[strSponsorTrainingType]?.sponser?.[strSponsorType]?.studentNumber?.value?.eng) || 0;
        const intCountPrice = Number(objCat?.Root?.toSupport?.[strSponsorTrainingType]?.sponser?.[strSponsorType]?.price?.value?.eng) || 0;
        if (intCountStudentNumSystem && !intCountPrice) {
          const priceSystem = Number(objCat?.Root?.toSupport?.[strSponsorTrainingType]?.price?.value?.eng);
          return ((priceSystem || 0) * (intCountStudentNumSystem || 0)).toLocaleString();
        } else if (intCountPrice) {
          return intCountPrice.toLocaleString();
        }
        return 0;
      } catch {
        return 0;
      }
    },
    openModal:
      ({ state, setState, fields }) =>
      async () => {
        try {
          fields?.controller?.resetForm();
          state.blnOpenSetterModal = true;
          state.lstReceipt = [];
          let lstToSupportOption = [];
          let lstSponsorTrainingTypeOption = [];

          [...Object.entries(objCat?.Root?.toSupport || {})]?.filter(([key, value])=>value?.blnIsView?.value?.[lang] === "true").reverse().forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              lstToSupportOption.push({ key: key, value: value?.supportLabel?.value?.[lang] });
            }
          });

          Object.entries(objCat?.Root?.toSupport?.[lstToSupportOption[0]?.key]?.sponser || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              const SponsorTrainingTypeOptions = SponsorTrainingTypeOptionsView({
                lang,
                value,
                getPartnerTransPlanTotalPrice: handler?.getPartnerTransPlanTotalPrice(lstToSupportOption[0]?.key, key, objCat),
                blnIsTraining: false,
                blnCurrency: true,
              });
              lstSponsorTrainingTypeOption.push({ key: key, value: SponsorTrainingTypeOptions?.optionLabel, jsx: () => SponsorTrainingTypeOptions?.jsx });
            }
          });

          fields?.strToSupport?.setOptions(lstToSupportOption, true);
          fields?.strSponsorTrainingType?.setOptions(lstSponsorTrainingTypeOption, true);
          fields?.strToSupport?.setValue(lstToSupportOption[0]);

          if (parentState?.objPartnerInfo && parentState?.objPartnerInfo?.bigPartnerID && !Number.isNaN(Number(parentState?.objPartnerInfo?.bigPartnerID))) {
            state.blnIsRecordExist = true;

            fields?.bigWorkSector.setValidation("required", false);
            fields?.strPlaceName2.setValidation("required", false);

            fields?.bigWorkSector?.setValue(parentState?.objPartnerInfo?.jsnPartnerInfo?.bigWorkSector);
            fields?.strPlaceName2?.setValue(parentState?.objPartnerInfo?.jsnPartnerInfo?.strPlaceName || appState?.userInfo?.jsnUserInfo?.strPlaceName);
            fields?.strURL?.setValue(parentState?.objPartnerInfo?.jsnPartnerInfo?.strURL);
            state.lstLogo = Array.isArray(parentState?.objPartnerInfo?.strLogoImg) ? parentState?.objPartnerInfo?.strLogoImg : [];
          } else {
            fields?.bigWorkSector.setValidation("required", true);
            fields?.strPlaceName2.setValidation("required", true);
            if (appState?.userInfo?.jsnUserInfo?.strPlaceName) {
              fields.strPlaceName2.setValue(appState?.userInfo?.jsnUserInfo?.strPlaceName);
            }
            if (appState?.userInfo?.jsnUserInfo?.bigWorkSector) {
              fields?.bigWorkSector?.setValue(appState?.userInfo?.jsnUserInfo?.bigWorkSector);
            }
            state.blnIsRecordExist = false;
            state.lstLogo = [];
          }

          if (objSystemConfig?.blnIsContact) {
            fields?.strSponserName.setValidation("required", true);
            fields?.strUserPhone.setValidation("required", true);
          }
          if (appState?.userInfo?.jsnUserInfo?.strFullName) {
            if (typeof appState?.userInfo?.jsnUserInfo?.strFullName === "object") {
              fields.strSponserName.setValue(
                appState?.userInfo?.jsnUserInfo?.strFullName?.[lang] ||
                  appState?.userInfo?.jsnUserInfo?.strFullName?.eng ||
                  appState?.userInfo?.jsnUserInfo?.strFullName?.arb
              );
            } else {
              fields.strSponserName.setValue(appState?.userInfo?.jsnUserInfo?.strFullName);
            }
          }
          if (appState?.userInfo?.strUserPhone) {
            fields.strUserPhone.setValue(appState?.userInfo?.strUserPhone?.slice(5));
          }

          appFunctions.modalOverFllow(state.blnOpenSetterModal);
          setState({ ...state });
        } catch (error) {
          setIsLoading(false);
          appFunctions.logError(strPageInfo, "openModal-ERROR", error);
        }
      },

    updatePartnerInfo:
      ({ state, setState, fields, uploaderLogo, uploaderReceipt }) =>
      (intSupportTransStatus = appVariables.objSupportTransStatus.Send_New) =>
      async () => {
        try {
          if (!fields?.controller?.isFormValid()) {
            Alert.viewAlert(dictionary.shared.alertFetchNote.invalid_Field?.[lang], "error", appLangDirection?.[lang], appStyle.alertStyle);
            return;
          }

          if (!state.lstReceipt?.length && objSystemConfig?.blnIsPaid) {
            Alert.viewAlert(dictionary.shared.alertFetchNote.receiptUploadFile?.[lang], "error", appLangDirection?.[lang], appStyle.alertStyle);
            return;
          }

          if (isLoading) {
            Alert.viewAlert(dictionary.shared.alertFetchNote.fetchData_InProgress?.[lang], "warning", appLangDirection?.[lang], appStyle.alertStyle);
            return;
          }
          setIsLoading(true);
          /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //#region add or update partner
          // const resultUpload = await uploaderLogo?.uploadHandler();
          // if (!resultUpload) {
          //   setIsLoading(false);
          //   return;
          // }

          let bigPartnerStatusID = state?.blnIsRecordExist ? parentState?.objPartnerInfo?.bigPartnerStatusID : null;
          if (!bigPartnerStatusID) {
            bigPartnerStatusID = !appState?.clientInfo?.objSystemInfo?.jsnSystemConfig?.blnIsConfiremSponer
              ? appVariables.objPartnerStatus.Approved
              : appVariables.objPartnerStatus.New;
          }
          if (objSystemConfig?.blnIsContact && !state?.blnIsRecordExist) {
            bigPartnerStatusID = appVariables.objPartnerStatus.New;
          }
          // const objCat = appFunctions.getCategoryCache();

          // //#region
          let jsnSupportTrans = Array.isArray(parentState?.objPartnerInfo?.jsnSupportTrans)
            ? JSON.parse(JSON?.stringify(parentState?.objPartnerInfo?.jsnSupportTrans))?.reverse()
            : [];

          if (state.lstReceipt?.length) {
            const resultUploadReceipt = await uploaderReceipt?.uploadHandler("supportTransReceipt");
            if (!resultUploadReceipt) {
              setIsLoading(false);
              return;
            }
          }
          const strSponsorTrainingType = fields?.strToSupport?.getValue()?.key;

          const intCountStudentNum =
            Number(objCat?.Root?.toSupport?.[strSponsorTrainingType].sponser?.[fields?.strSponsorTrainingType?.getValue()?.key]?.studentNumber?.value?.eng) || 0;
          const intPriceSystem = intCountStudentNum
            ? Number(objCat?.Root?.toSupport?.[strSponsorTrainingType]?.price?.value?.eng)
            : Number(objCat?.Root?.toSupport?.[strSponsorTrainingType].sponser?.[fields?.strSponsorTrainingType?.getValue()?.key]?.price?.value?.eng) || 0;

          if (!state.selectedTrans) {
            jsnSupportTrans.push({
              id: appFunctions.generateID(6),
              dtmCreatedDate: new Date().toUTCString(),
              dtmUpdatedDate: new Date().toUTCString(),
              strTrainingType: strSponsorTrainingType,
              intSupportTransStatus: intSupportTransStatus,
              intTraineesCount: intCountStudentNum,
              intTrainingProgPrice: intPriceSystem,
              lstReceipt: state.lstReceipt,
              lstReceiptCatch: [],
              intReceivedAmount:
                objCat?.Root?.toSupport?.[strSponsorTrainingType]?.blnIsTraining?.value?.eng === "false" ? intPriceSystem : intPriceSystem * intCountStudentNum || null,
              strNote: fields?.strNote?.getValue(),
            });
          } else {
            const transIndex = jsnSupportTrans?.findIndex((item) => String(item.id) === String(state?.selectedTrans?.id));
            jsnSupportTrans[transIndex] = {
              ...state?.selectedTrans,
              dtmUpdatedDate: new Date().toUTCString(),
              strTrainingType: strSponsorTrainingType,
              intSupportTransStatus: intSupportTransStatus,
              intTraineesCount: intCountStudentNum,
              intTrainingProgPrice: intPriceSystem,
              lstReceipt: state.lstReceipt,
              lstReceiptCatch: [],
              strNote: fields?.strNote?.getValue(),
            };
          }

          let intTraineesCount_Old = parentState?.objPartnerInfo?.intTraineesCount;
          let intManagerTrainCount_Old = parentState?.objPartnerInfo?.intManagerTrainCount;

          let intTraineesCount = intCountStudentNum; //parentState?.objPartnerInfo?.intTraineesCount;
          let intManagerTrainCount = intCountStudentNum; //parentState?.objPartnerInfo?.intManagerTrainCount;

          for (let i = 0; i < jsnSupportTrans.length; i++) {
            if (appFunctions.getDifferenceBetweenTwoDate(jsnSupportTrans?.[i]?.dtmCreatedDate, new Date(), "month") <= 4) {
              if (String(jsnSupportTrans?.[i]?.intSupportTransStatus) === String(appVariables.objSupportTransStatus?.Approved)) {
                if (jsnSupportTrans?.[i]?.intTraineesCount > intTraineesCount) {
                  intTraineesCount = jsnSupportTrans?.[i]?.intTraineesCount;
                }
                // }
              }
            }
          }

          if (intTraineesCount === intTraineesCount_Old && intManagerTrainCount === intManagerTrainCount_Old) {
            for (let i = 0; i < jsnSupportTrans.length; i++) {
              if (String(jsnSupportTrans?.[i]?.intSupportTransStatus) === String(appVariables.objSupportTransStatus?.Approved)) {
                if (jsnSupportTrans?.[i]?.intTraineesCount > intTraineesCount) {
                  intTraineesCount = jsnSupportTrans?.[i]?.intTraineesCount;
                }
                // }
              }
            }
          }

          // //#endregion
          const intCountTransStatistic =
            objCat?.Root?.toSupport?.[strSponsorTrainingType]?.blnIsTraining?.value?.eng === "false"
              ? ["intCountTransSponsorActivity"]
              : ["intCountTransSponsorTraining"];
          const objectInput = {
            bigUserID: appState?.userInfo?.bigUserID,
            jsnUserInfo: {
              strFullName: { eng: fields?.strSponserName?.getValue(), arb: fields?.strSponserName?.getValue() },
              bigWorkSector: fields?.bigWorkSector?.getValue()?.key,
            },

            jsnPartnerInfo: {
              strFullName: fields?.strSponserName?.getValue() || appState?.userInfo?.jsnUserInfo?.strFullName?.[lang],
            },
            bigWorkSector: fields?.bigWorkSector?.getValue()?.key,
            strPlaceName: fields?.strPlaceName2?.getValue() || fields?.strSponserName?.getValue(),
            strFullName: fields?.strSponserName?.getValue() || appState?.userInfo?.jsnUserInfo?.strFullName?.[lang],
            strUserEmail: appState?.userInfo?.strUserEmail,
            strUserPhone: appVariables.globalKeyZero + String(fields?.strUserPhone?.getValue()).trim(),
            strURL: fields?.strURL?.getValue(),

            bigPartnerStatusID: bigPartnerStatusID,

            jsnSupportTrans: jsnSupportTrans,

            intSupportTransStatus: intSupportTransStatus || intTraineesCount,
            intTraineesCount: intTraineesCount, //intTraineesCount_Old !== intTraineesCount ? intTraineesCount : undefined,
            intManagerTrainCount: intManagerTrainCount || intTraineesCount, //intManagerTrainCount_Old !== intManagerTrainCount ? intManagerTrainCount : undefined,

            blnIsConfirmed: bigPartnerStatusID === appVariables.objPartnerStatus.Approved,
            //blnVewLogo: false,
            //strLogoImg: state?.lstLogo,
            lstStatisticsOnAdd: ["intCountJoinSponsorAll", ...intCountTransStatistic],
            lstStatisticsOnUpdate: intCountTransStatistic,

            dtmUpdatedDate: new Date().toUTCString(),
          };
          if (!appState?.userInfo?.blnIsSponsor) {
            objectInput.jsnUserInfo.dtmRegisterSponsor = appFunctions?.getDateUSFormat(appFunctions.getCurrentDate());
          }
          const resultPartner = await partnerApi(strPageInfo, appState, "aaiotAccountPartnerUpdate", {
            objectInput: { ...objectInput },
            objectCondition: {
              bigAppID: appVariables.App_ID,
              bigSystemID: appVariables?.aaiot_System_ID,
            },
          });

          if (!resultPartner?.blnIsRequestSuccessful) {
            if (resultPartner?.response === "strUserPhone already used by another users!") {
              Alert.viewAlert(dictionary.shared.alertFetchNote.phone_Already_Used?.[lang], "warning", appLangDirection?.[lang], appStyle.alertStyle);
            } else {
              Alert.viewAlert(
                dictionary.shared.alertFetchNote?.[resultPartner?.response?.error]?.[lang] || dictionary.shared.alertFetchNote.cant_Update_Record?.[lang],
                "warning",
                appLangDirection?.[lang],
                appStyle.alertStyle
              );
            }
            setIsLoading(false);

            return;
          }
          parentState.objPartnerInfo = { ...parentState.objPartnerInfo, ...resultPartner?.response?.partnerInfo };
          appState.userInfo.blnIsSponsor = true; // resultPartner?.response?.userInfo?.blnIsSponsor;
          appState.userInfo.strUserPhone = resultPartner?.response?.userInfo?.strUserPhone;
          appState.userInfo.jsnUserInfo = resultPartner?.response?.userInfo?.jsnUserInfo;

          if (bigPartnerStatusID === appVariables.objPartnerStatus.New && !state?.blnIsRecordExist) {
            await statisticApi(strPageInfo, appState, "aaiotAppIncDecInt", {
              objectCondition: {
                bigSystemID: appVariables.aaiot_System_ID,
              },
              arrAttributes: ["intCountJoinSponsor"],
            });
          }
          //#endregion
          /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          if (!state.blnIsRecordExist) {
            const lstServices = [];
            Object.keys(objCat?.Root?.services || {})?.forEach((key) => {
              const service = objCat?.Root?.services[key];
              const lstRoleFlag = appFunctions.checkParseObject(service?.lstRoleFlag?.value?.[lang]);
              if (appState?.userInfo?.blnIsManager || appState?.userInfo?.blnIsSponsor || appState?.userInfo?.blnIsStartUp || appState?.userInfo?.blnIsStudent) {
                for (let index = 0; index < lstRoleFlag.length; index++) {
                  const roleFlag = lstRoleFlag[index];
                  if (appState?.userInfo?.[roleFlag] === true || appState?.userInfo?.[roleFlag] === 1) {
                    lstServices.push(service);
                    return;
                  }
                }
              } else if (!(lstRoleFlag?.length === 1 && lstRoleFlag?.[0] === "blnIsSponsor")) {
                lstServices.push(service);
              }
            });
            parentState.lstServices = lstServices;
          }
          state.blnOpenSetterModal = false;
          appFunctions.modalOverFllow(state.blnOpenSetterModal);

          setState({ ...state });
          setIsLoading(false);
          setParentState({ ...parentState });
          appDispatch();
          Alert.viewAlert(
            dictionary.components?.stakeholders?.member?.account?.sponsorSetter?.thanksContribution?.[lang],
            "success",
            appLangDirection?.[lang],
            appStyle.alertStyle
          );
        } catch (error) {
          appFunctions.logError(strPageInfo, "updatePartnerInfo-ERROR", error);
          setIsLoading(false);
        }
      },

    dilogeImgModal:
      ({ state, setState }) =>
      (lstImg = null, typeImg = null) =>
      () => {
        try {
          state.blnOpenImgeModal = !state.blnOpenImgeModal;
          state.selectedLstImg = lstImg;
          state.selectedImgType = typeImg;
          setState({ ...state });
          appFunctions.modalOverFllow(state.blnOpenImgeModal);
        } catch (error) {
          appFunctions.logMessage(strPageInfo, error, "dilogeImgModal-Error");
        }
      },
    handleCopy:
      ({ state, setState }) =>
      async () => {
        try {
          appFunctions.copyTextToClipboard(appState?.clientInfo?.objSystemInfo?.jsnSystemConfig?.strBankInfo?.[lang]);
          state.clipboardText = true;
          setState({ ...state });
        } catch (err) {
          console.error("Failed to read clipboard contents: ", err);
        }
      },
    handleChangeToSupport:
      ({ fields }) =>
      async () => {
        try {
          const strSponsorTrainingType = fields?.strToSupport?.getValue()?.key;
          let lstSponsorTrainingTypeOption = [];

          Object.entries(objCat?.Root?.toSupport?.[strSponsorTrainingType]?.sponser || {}).forEach(([key, value]) => {
            if (key !== "id" && key !== "value") {
              const SponsorTrainingTypeOptions = SponsorTrainingTypeOptionsView({
                lang,
                value,
                getPartnerTransPlanTotalPrice: handler?.getPartnerTransPlanTotalPrice(strSponsorTrainingType, key, objCat),
                blnIsTraining: objCat?.Root?.toSupport?.[strSponsorTrainingType]?.blnIsTraining?.value?.eng !== "false",
              });
              lstSponsorTrainingTypeOption.push({ key: key, value: SponsorTrainingTypeOptions?.optionLabel, jsx: () => SponsorTrainingTypeOptions?.jsx });
            }
          });
          fields?.strSponsorTrainingType?.setOptions(lstSponsorTrainingTypeOption, true);
          fields?.strSponsorTrainingType?.setValue("");
        } catch (err) {
          console.error("Failed to handleChangeToSupport: ", err);
        }
      },
    handelNextStep:
      ({ state, setState }) =>
      (newValues) => {
        setState({ ...state, ...newValues });
      },
    objCat,
  };

  return handler;
}
